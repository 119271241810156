<template>
  <v-card min-height="100vh">
    <v-toolbar style="background-color: #1daf80; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Anamnese</span>
      </v-toolbar-title>
      <div
        class="icon-card-adicional ml-1"
        title="Anamnese Adicional"
        v-if="info.req_st == 5"
      >
        <v-icon style="font-size: 30px" color="white">mdi-alpha-a</v-icon>
      </div>
      <v-spacer></v-spacer>
      <!-- <v-btn
        class="me-2"
        icon
        dark
        x-small
        title="Editar Data"
        v-if="checkUser()"
        @click="dialogData = true"
      >
        <v-icon small>mdi-calendar</v-icon>
      </v-btn> -->
      <v-toolbar-title>
        <span class="headline">{{
          getDate({ data: info.data, hora: info.hora_inicio })
        }}</span>
      </v-toolbar-title>
      <v-btn
        class="ms-3"
        icon
        dark
        small
        title="Imprimir"
        @click="gerarPdf"
        v-if="checkAuditoria()"
      >
        <v-icon small>mdi-printer</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-dialog
        v-model="dialogData"
        transition="dialog-bottom-transition"
        width="600"
      >
        <modal-data-anamnese
          v-if="dialogData"
          :info="info"
          :auditoria="this.auditoria"
          @close="dialogData = false"
          @atualizar="atualizarCompromisso"
        />
      </v-dialog>
      <v-tabs v-model="tab" background-color="transparent" color="#1daf80" grow>
        <v-tab key="anamnese">Anamnese</v-tab>
        <v-tab key="adendos">Adendos</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item key="anamnese">
          <div class="d-flex justify-content-between">
            <div class="text-left p-2">
              <p>
                <strong>Executor:</strong>
                {{
                  info.id_perfil == 2 ? info.nome_medico : info.nome_assistente
                }}
                <br />
                <strong>Procedimento:</strong> {{ info.procedimento }} <br />
                <strong>Animal:</strong> {{ info.nome_pet }} <br />
              </p>
            </div>
            <div v-if="checkAuditoria()">
              <v-btn
                color="#089467"
                small
                title="Salvar Prontuario"
                v-if="checkUser() && edit"
                @click="atualizarProntuario"
                :dark="!loadingSalvaForm"
                :loading="loadingSalvaForm"
                :disabled="loadingSalvaForm"
              >
                <strong> Salvar </strong>
              </v-btn>
              <v-btn
                :color="!edit ? '#089467' : 'red'"
                small
                :title="!edit ? 'Editar Prontuario' : 'Fechar edição'"
                @click="edit = !edit"
                v-if="checkUser()"
                class="ml-2"
              >
                <v-icon color="white" v-if="!edit"> mdi-pencil </v-icon>
                <v-icon color="white" v-else> mdi-close </v-icon>
              </v-btn>
            </div>
          </div>
          <v-card v-if="!loadingForm" flat>
            <v-card-text>
              <div v-if="checkAuditoria()">
                <!-- <v-row no-gutters class="text-left flex-column"> -->
                <!-- <v-row class="flex-column"> </v-row>
                  <v-row> -->
                <div
                  v-for="(pergunta, index) in formulario.perguntas"
                  :key="index"
                  class="col-12 text-left"
                  :class="pergunta.id_tipo_pergunta === 2 ? 'paragrafo' : ''"
                >
                  <consulta-fill
                    @rchange="atributeModel"
                    :pergunta="pergunta"
                    :disabled="edit"
                  />
                </div>
                <!-- </v-row> -->
                <!-- </v-row> -->
              </div>
              <div v-else>
                <h3 class="text-center">Prontuário sem anaminese.</h3>
              </div>
            </v-card-text>
          </v-card>
          <div v-else>
            <v-progress-circular
              indeterminate
              color="#1daf80"
            ></v-progress-circular>
          </div>
        </v-tab-item>
        <v-tab-item key="adendos">
          <div class="text-end my-4">
            <v-btn
              color="#089467"
              dark
              @click="formAdendo = !formAdendo"
              v-if="checkAdendo()"
            >
              Novo Adendo
            </v-btn>
          </div>
          <div v-if="!loadingAdendo" style="height: 400px; overflow: auto">
            <div class="container-fluid">
              <div class="row justify-content-center">
                <div class="col-10 text-start">
                  <div v-if="adendoList.length > 0">
                    <div
                      class="item-adendo"
                      v-for="item in adendoList"
                      :key="item.id"
                    >
                      <div class="title-adendo">
                        <div>{{ item.executor }}</div>
                        <div>
                          {{ getDate(item) }}
                        </div>
                      </div>
                      <div class="descricao-adendo">
                        {{ item.descricao }}
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <h3 class="mt-6 text-center">Sem adendos registrados.</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <v-progress-circular
              indeterminate
              color="#1daf80"
            ></v-progress-circular>
          </div>
          <v-bottom-sheet v-model="formAdendo" inset>
            <v-sheet class="text-center">
              <v-toolbar>
                <v-toolbar-title>
                  <span class="headline">Adicionar Adento</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon color="red" @click="formAdendo = !formAdendo">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <div class="container mt-5">
                <div class="row justify-content-center">
                  <div
                    class="col-10 pt-2 pb-5 d-flex justify-content-between align-self-end"
                  >
                    <v-textarea
                      class="col-10"
                      v-model="newAdendo"
                      placeholder="Adendo"
                      rows="2"
                      solo
                      hide-details
                    ></v-textarea>
                    <v-btn
                      color="#006506"
                      :dark="newAdendo != '' && !loadingSalvaAdendo"
                      :loading="loadingSalvaAdendo"
                      :disabled="newAdendo == '' || loadingSalvaAdendo"
                      @click="addAdendo"
                    >
                      Salvar
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-sheet>
          </v-bottom-sheet>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text></v-card
  >
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import vuex_snackbar_mixin from "@/mixins/vuex_snackbar_mixin.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";
import ConsultaService from "@/services/consulta_service.js";
import AdendoService from "@/services/adendo_service.js";
import UnidadeService from "@/services/unidade_service.js";
import ConsultaFill from "@/components/prontuarios/consulta_campo_fill.vue";
import ModalDataAnamnese from "@/components/prontuarios/modal_data_anamnese.vue";

import pdfMake from "pdfmake/build/pdfmake";
//comentar antes de fazer npm build
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Prontuario } from "@/components/documentos/modelos/prontuario.js";
//comentar antes de fazer npm build
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  mixins: [Mixin, VuexUsuarioMixin, DadosCadastraisMixin, vuex_snackbar_mixin],
  components: {
    ConsultaFill,
    ModalDataAnamnese,
  },
  props: {
    info: Object,
    auditoria: {
      type: Boolean,
    },
  },
  data: () => ({
    services: {
      consultaService: new ConsultaService(),
      adendoService: new AdendoService(),
      unidadeService: new UnidadeService(),
    },
    tab: "",
    formulario: {},
    edit: false,
    adendoList: [],
    usuarioId: 0,
    newAdendo: "",
    usuario: JSON.parse(sessionStorage.vuex).usuario.dados.nome,
    formAdendo: false,
    loadingForm: false,
    loadingAdendo: false,
    loadingSalvaForm: false,
    loadingSalvaAdendo: false,
    consultaAuditoria: false,
    dialogData: false,
    perfilId: "",
  }),
  async mounted() {
    this.usuarioId = await JSON.parse(sessionStorage.vuex).usuario.id;
    this.perfilId = await JSON.parse(sessionStorage.vuex).perfil.id;
    this.consultaInfo();
    this.listAdendos();
    this.getUnidade();
  },
  watch: {
    formAdendo() {
      if (this.formAdendo == false) {
        this.newAdendo = "";
      }
    },
  },
  methods: {
    consultaInfo() {
      this.loadingForm = true;
      this.services.consultaService.get(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.formulario = body.data;
              this.formulario.perguntas = this.formulario.perguntas.map(
                (pergunta) => {
                  if ([1, 2].includes(pergunta.id_tipo_pergunta)) {
                    return pergunta;
                  } else {
                    return {
                      ...pergunta,
                      resposta_pergunta_opcao: pergunta.opcoes.filter((op) => {
                        return pergunta.resposta_pergunta_opcao
                          .map((resp) => resp.resposta_opcao)
                          .includes(`${op.id}`);
                      }),
                    };
                  }
                }
              );
            } else if (status == 206) {
              this.consultaAuditoria = true;
              // this.consulta = { auditoria: true };
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.loadingForm = false;
            // console.log(this.consulta);
          },
        },
        this.info.id_consulta
      );
    },
    atributeModel(change) {
      const newValue = change.alteracao;
      const perguntaId = change.pergunta.id;

      const pergunta = this.formulario.perguntas.find(
        (e) => e.id === perguntaId
      );
      const respostaCampos = {
        1: "resposta_pergunta_textual",
        2: "resposta_pergunta_textual",
        3: "resposta_pergunta_opcao",
        4: "resposta_pergunta_opcao",
      };
      const respostaCampo = respostaCampos[pergunta.id_tipo_pergunta];
      pergunta[respostaCampo] = newValue;
    },
    atualizarProntuario() {
      this.loadingSalvaForm = true;
      let formulario = this.info;

      formulario.id_consulta = Number.parseInt(this.info.id_consulta);
      formulario.titulo = this.info.consulta_titulo;
      formulario.descricao = this.formulario.consulta_descricao;
      formulario.id_formulario = this.formulario.formulario_id;

      formulario.respostas = this.formulario.perguntas.map((resposta) => {
        if (resposta.resposta_pergunta_textual) {
          resposta.resposta = resposta.resposta_pergunta_textual;
        }

        if (resposta.resposta_pergunta_decimal) {
          resposta.resposta = resposta.resposta_pergunta_decimal;
        }

        if (resposta.resposta_pergunta_inteira) {
          resposta.resposta = resposta.resposta_pergunta_inteira;
        }

        if (resposta.resposta_pergunta_opcao) {
          resposta.resposta = resposta.resposta_pergunta_opcao;
        }

        resposta.id_pergunta = resposta.id;

        return resposta;
      });
      this.services.consultaService.update(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: "Alterações salvas com sucesso",
                color: "sucess",
              });
              // this.$emit("finalizarConsulta", { msg:body.message, cor:"sucess", nome: this.paciente.nome} );
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: "error",
              });
            }
          },
          onError: (message) => {
            this.$_ACTIONS_showSnackbarMessage({
              message: message || this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.edit = false;
            this.loadingSalvaForm = false;
          },
        },
        formulario,
        formulario.id_consulta
      );
    },
    async listAdendos() {
      this.loadingAdendo = true;
      this.services.adendoService.getList(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.adendoList = body.data.adendos;
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.loadingAdendo = false;
          },
        },
        this.info.id_consulta
      );
    },
    addAdendo() {
      const model = {
        consulta_id: this.info.id_consulta,
        descricao: this.newAdendo,
        usuario_id: this.usuarioId,
      };
      this.loadingSalvaAdendo = true;
      this.services.adendoService.criarAdendo(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "sucess",
              });
              this.limparAdendoForm();
              this.listAdendos();
            }
          },
          onError: (error) => {
            this.$_ACTIONS_showSnackbarMessage({
              message: error.message,
              color: "error",
            });
          },
          onEnd: () => {
            this.loadingSalvaAdendo = false;
          },
        },
        model
      );
    },
    limparAdendoForm() {
      this.newAdendo = "";
      this.formAdendo = false;
    },
    getDate(item) {
      let arrDate = item.data.split("-");
      let arrHora = item.hora.split(":");
      return `${arrDate[2]}/${arrDate[1]}/${arrDate[0]} - ${arrHora[0]}:${arrHora[1]}`;
    },
    getUnidade() {
      this.services.unidadeService
        .getUnidadeById(this.info.id_unidade)
        .then(async (response) => {
          this.info.unidade = response.data.unidade[0];
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {});
    },
    gerarPdf() {
      const docArquivo = Prontuario(
        this.usuario,
        this.info,
        this.formulario.perguntas,
        this.adendoList
      );
      pdfMake.createPdf(docArquivo).download(`Prontuario.pdf`);
    },
    checkUser() {
      // if ([1, 56].includes(parseInt(this.perfilId)) && this.auditoria == true) {
      if ([1, 56].includes(parseInt(this.perfilId))) {
        return true;
      } else if (this.usuarioId == this.info.id_assistente) {
        return true;
      } else {
        return false;
      }
    },
    checkAdendo() {
      if ([1, 56].includes(parseInt(this.perfilId))) {
        return false;
      } else {
        return true;
      }
    },
    checkAuditoria() {
      if (this.consultaAuditoria) {
        return false;
      } else {
        return true;
      }
    },
    atualizarCompromisso() {
      this.dialogData = false;
      this.$emit("atualizar");
    },
  },
};
</script>

<style scoped>
.item-adendo {
  border-radius: 10px;
  box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.title-adendo {
  background-color: #09684a;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 10px 10px 0px 0px;
  color: white;
  display: flex;
  justify-content: space-between;
}
.descricao-adendo {
  padding: 8px;
  background-color: white;
  border: 1px solid #09684a;
  border-radius: 0px 0px 10px 10px;
}
.icon-card-adicional {
  background-color: #ff6000;
  border-radius: 20px;
}
</style>
