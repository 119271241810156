<template>
  <div>
    <v-card min-height="100vh">
      <v-toolbar dark class="bg-verde">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline" v-if="visualizar">Visualizar orgão</span>
          <span class="headline" v-else>Editar orgão</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items> </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-8">
        <v-container v-if="!loading">
          <v-form class="row" ref="form">
            <div class="col-lg-6 form-group clearfix mb-3">
              <label for="nome">Nome fantasia</label>
              <v-text-field
                :error-messages="error.nome_fantasia"
                v-model="orgao.nome_fantasia"
                :disabled="visualizar"
                :rules="[
                  validationService.required('Campo obrigatório.'),
                  validationService.isNotEmpty(),
                ]"
                :type="'text'"
                placeholder="Nome fantasia"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-6 form-group clearfix mb-3">
              <label for="nome">Razão social</label>
              <v-text-field
                :error-messages="error.razao_social"
                v-model="orgao.razao_social"
                :disabled="visualizar"
                :rules="[
                  validationService.required(
                    'Campo Razão Social é obrigatório.'
                  ),
                  validationService.isNotEmpty(),
                ]"
                :type="'text'"
                placeholder="Razão Social"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="CNPJ">CNPJ</label>
              <v-text-field
                v-mask="'##.###.###/####-##'"
                :error-messages="error.cnpj"
                v-model="orgao.cnpj"
                :disabled="visualizar"
                :type="'text'"
                :rules="[
                  validationService.required('Campo CNPJ é obrigatório.'),
                  validationService.isNotEmpty(),
                  validationService.isCNPJ(),
                ]"
                placeholder="00.000.000/0000-00"
                style="padding-top: 0 !important"
              >
              </v-text-field>
              <span class="text-muted">ex: "123.456.7890-12"</span>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Telefone01">Telefone 1:</label>
              <v-text-field
                v-mask="'(##)#####-####'"
                :error-messages="error.telefone_1"
                v-model="orgao.telefone_1"
                :disabled="visualizar"
                :type="'text'"
                :rules="[
                  validationService.required('Campo Telefone é obrigatório.'),
                  validationService.isNotEmpty(),
                ]"
                placeholder="(00) 00000-0000"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Telefone01">Telefone 2:</label>
              <v-text-field
                v-mask="'(##)####-####'"
                :error-messages="error.telefone_2"
                v-model="orgao.telefone_2"
                :disabled="visualizar"
                :type="'text'"
                placeholder="(00) 00000-0000"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="CEP">CEP</label>
              <v-text-field
                v-mask="'#####-###'"
                :error-messages="error.cep"
                v-model="orgao.cep"
                :disabled="visualizar"
                :type="'text'"
                :rules="[
                  validationService.required('Campo CEP é obrigatório.'),
                ]"
                @blur="searchCepEntrada"
                placeholder="00000-000"
                style="padding-top: 0 !important"
              >
              </v-text-field>
              <span class="text-muted">ex: "xxxxx-xxx"</span>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Rua">Logradouro</label>
              <v-text-field
                :error-messages="error.logradouro"
                v-model="orgao.logradouro"
                :disabled="visualizar"
                :rules="[
                  validationService.required('Campo Logradouro é obrigatório.'),
                ]"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Bairro">Bairro</label>
              <v-text-field
                :error-messages="error.bairro"
                v-model="orgao.bairro"
                :disabled="visualizar"
                :rules="[
                  validationService.required('Campo Bairro é obrigatório.'),
                ]"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Numero">Número</label>
              <v-text-field
                :error-messages="error.numero"
                v-model="orgao.numero"
                :disabled="visualizar"
                :type="'text'"
                :rules="[
                  validationService.required('Campo Número é obrigatório.'),
                ]"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Complemento">Complemento</label>
              <v-text-field
                :error-messages="error.complemento"
                v-model="orgao.complemento"
                :disabled="visualizar"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Referencia">Referência</label>
              <v-text-field
                :error-messages="error.referencias"
                v-model="orgao.referencias"
                :disabled="visualizar"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Cidade">Cidade</label>
              <v-text-field
                :error-messages="error.cidade"
                v-model="orgao.cidade"
                :disabled="visualizar"
                :rules="[
                  validationService.required('Campo Cidade é obrigatório.'),
                ]"
                :type="'text'"
                style="padding-top: 0 !important"
              ></v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Estado">Estado</label>
              <v-text-field
                :error-messages="error.estado"
                v-model="orgao.estado"
                :disabled="visualizar"
                :rules="[
                  validationService.required('Campo Estado é obrigatório.'),
                ]"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label>Região Administrativa</label>
              <v-select
                v-model="selected_reg_adm"
                :items="list_reg_adm"
                item-text="desc"
                item-value="id"
                style="padding-top: 0 !important"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title
                      style="color: #313030; margin-bottom: 5px"
                    >
                      Cidade: {{ data.item.desc }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      style="font-weight: bold; color: #999"
                    >
                      RA: {{ data.item.ra }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-select>
            </div>

            <!-- <div class="col-lg-1 form-group clearfix mb-3">
              <label for="Ativo">Ativo</label>
              <v-switch
                v-model="orgao.ativo"
                :disabled="visualizar"
                color="#1daf80"
                style="padding-top: 0 !important"
              >
              </v-switch>
            </div> -->

            <!-- <div class="col-lg-3 form-group clearfix mb-3">
              <label>Servidor Chamefacil</label>

              <v-select
                :items="servidoresChamefacil"
                v-model="unidade.chamefacil"
                :disabled="visualizar"
                item-text="host"
                item-value="id"
                style="padding-top: 0 !important"
                :rules="[rules.required]"
                :error-messages="error.servidorChamefacil"
              ></v-select>
            </div> -->

            <div class="col-12 form-group">
              <button
                v-if="!visualizar"
                class="btn btn-app-primary btn-flex btn-rounded font-weight-bold justify-content-end"
                @click.prevent="atualizarOrgao()"
              >
                <span v-if="!loading"> Salvar </span>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="white"
                ></v-progress-circular>
              </button>
            </div>
          </v-form>
          <v-snackbar
            v-if="messageSnackbar"
            v-model="messageSnackbar"
            :color="typeMessageSnackbar"
            :timeout="-1"
          >
            {{ messageSnackbar }}
            <template v-slot:action="{ attrs }">
              <v-btn
                text
                :color="'white'"
                v-bind="attrs"
                @click="fecharSnackbar"
              >
                Ok
              </v-btn>
            </template>
          </v-snackbar>
        </v-container>
        <v-container v-else>
          <v-progress-circular
            indeterminate
            color="#1daf80"
          ></v-progress-circular>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ValidationService from "@/services/validation_service.js";
import CEPMixin from "@/mixins/cep_mixin.js";
import { mapActions } from "vuex";
import OrgaoService from "../../services/orgao_service";
import ViaCepService from "@/services/via_cep_service.js";
import RegiaoAdministrativaService from "@/services/regiao_administrativa_service.js";

export default {
  mixins: [CEPMixin],
  props: {
    // orgao: Object,
    orgaoId: Number,
    visualizar: Boolean,
  },
  async beforeMount() {
    this.validationService = ValidationService;
  },
  data() {
    return {
      loading: true,
      error: {
        cnpj: "",
        telefone_1: "",
        telefone_2: "",
        nome_fantasia: "",
        razao_social: "",
        cep: "",
        estado: "",
        cidade: "",
        bairro: "",
        logradouro: "",
        numero: "",
        complemento: "",
        referencias: "",
        validationService: {},
        // servidorChamefacil: "",
      },
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
        min: (v) => v.length >= 8 || "Minimo de 8 caracteres",
      },
      services: {
        orgaoService: OrgaoService.build(),
        viaCepService: ViaCepService.build(),
        regiaoAdministrativaService: RegiaoAdministrativaService.build(),
      },
      orgao: {},
      list_reg_adm: [],
      selected_reg_adm: "",
    };
  },
  mounted() {
    this.getOrgaobyId();
    this.getListaRegiaoAdmin();
    // this.formCadastro = document.forms[0];
    // this.CEP_MIXIN_cep = this.orgao.cep;
  },
  watch: {
    // orgao() {
    //   this.CEP_MIXIN_logradouro = this.orgao.logradouro;
    //   this.CEP_MIXIN_bairro = this.orgao.bairro;
    //   this.CEP_MIXIN_cidade = this.orgao.cidade;
    //   this.CEP_MIXIN_estado = this.orgao.estado;
    //   this.CEP_MIXIN_cep = this.orgao.cep;
    // },
  },
  methods: {
    async getOrgaobyId() {
      this.services.orgaoService
        .getOrgaoById(this.orgaoId)
        .then(async (response) => {
          if (response.status == 200) {
            const { data } = await response.json();
            this.orgao = {
              ...data[0],
              logradouro: "",
              bairro: "",
              cidade: "",
              estado: "",
            };
            this.selected_reg_adm = data[0].id_orgao;
            this.searchCepEntrada();
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    searchCep() {
      this.$buscarCep(this.orgao, this.orgao.cep);
    },
    async searchCepEntrada() {
      if (this.orgao.cep.length == 9) {
        const cep = this.orgao.cep.replace("-", "");
        this.services.viaCepService.buscarCep(cep).then(async (response) => {
          if (response.status == 200) {
            const resp = await response.json();
            this.orgao.logradouro = resp.logradouro;
            // this.orgao.numero = numero;
            this.orgao.bairro = resp.bairro;
            this.orgao.cidade = resp.localidade;
            this.orgao.estado = resp.uf;
          } else {
            this.orgao.logradouro = "";
            this.orgao.numero = "";
            this.orgao.bairro = "";
            this.orgao.cidade = "";
            this.orgao.estado = "";
          }
        });
      }
    },

    ...mapActions({
      $_ACTIONS_showSnackbarMessage: "showSnackbarMessage",
    }),
    fecharSnackbar() {
      this.messageSnackbar = "";
      this.zerarErros();
    },
    zerarFormulario() {
      Array.from(this.formCadastro.elements).forEach((element) => {
        element.value = "";
      });
    },
    zerarErros() {
      Object.keys(this.error).forEach((error) => {
        this.error[error] = "";
      });
    },

    clean() {
      this.addFoto = true;
    },
    onChange() {
      const file = document.querySelector("input[type=file]").files[0];
      const reader = new FileReader();
      reader.onloadend = (e) => {
        this.preview = e.target.result;
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    },

    atualizarOrgao() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      // const unidade = {
      //   nome_fantasia: this.unidade.nomeFantasia,
      //   descricao: this.unidade.nomeFantasia,
      //   razao_social: this.unidade.razaoSocial,
      //   cnpj: this.unidade.cnpj,
      //   telefone_1: this.unidade.telefone1,
      //   telefone_2: this.unidade.telefone2,
      //   cep: this.unidade.cep,
      //   estado: this.unidade.estado,
      //   cidade: this.unidade.cidade,
      //   bairro: this.unidade.bairro,
      //   logradouro: this.unidade.logradouro,
      //   numero: this.unidade.numero,
      //   complemento: this.unidade.complemento,
      //   referencias: this.unidade.referencias,
      //   chamefacil: this.unidade.chamefacil,
      // };
      const orgao = {
        cnpj: this.orgao.cnpj,
        telefone_1: this.orgao.telefone_1,
        telefone_2: this.orgao.telefone_2,
        nome_fantasia: this.orgao.nome_fantasia,
        razao_social: this.orgao.razao_social,
        cep: this.orgao.cep,
        estado: this.orgao.estado,
        cidade: this.orgao.cidade,
        bairro: this.orgao.bairro,
        logradouro: this.orgao.logradouro,
        numero: this.orgao.numero,
        complemento: this.orgao.complemento,
        referencias: this.orgao.referencias,
        ativo: this.orgao.ativo,
        observacao: `Orgao ${this.orgao.razaoSocial}`,
        reg_adm: this.selected_reg_adm,
      };
      this.services.orgaoService
        .atualizarOrgao(this.orgaoId, orgao)
        // .atualizarUnidade(this.unidade.pessoaJuridicaId, unidade)
        .then(async (response) => {
          const body = await response.json();
          const message =
            body.message || this.$global.messages.internalServerError;
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({ message, color: "error" });
            const errors = body.errors;
            Object.keys(body.errors).forEach((error) => {
              if (errors[error]) {
                this.error[error] = errors[error];
              }
            });
            this.loading = false;
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: body.message || this.$global.messages.internalServerError,
            color: "sucess",
          });
          this.$emit("close");
        })
        .catch((e) => {
          console.log({ e });
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getListaRegiaoAdmin() {
      this.services.regiaoAdministrativaService
        .getRegiaoAdmin()
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          let list = [];
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            list.push({
              id: element.id,
              ra: element.regiao,
              desc: element.cidade,
            });
          }
          this.list_reg_adm = list;
        });
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}
</style>
