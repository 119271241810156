import { DataHoraHj, LogoHeader } from "./utils_docs";

export function TermoRecusa(animal, responsavel, unidade) {
  let content = [];

  const dateNow = DataHoraHj();

  content.push({
    columns: [
      {
        stack: [
          {
            image: LogoHeader(unidade.id),
            width: 55,
          },
        ],
        alignment: "left",
        border: [false, false, false, false],
      },
      {
        type: "none",
        ol: [
          { text: "Unidade", style: "text-header", bold: true },
          { text: unidade.razao_social, style: "text-header" },
          { text: "Endereço", style: "text-header", bold: true },
          { text: getEndereco(unidade), style: "text-header" },
        ],
      },
    ],
  });

  // Perguntas
  content.push([
    {
      text: "Termo de responsabilidade e recusa livre e esclarecida para internação, exames, e/ou procedimentos clínicos, ambulatoriais, anestésicos, terapêuticos e/ou cirúrgicos",
      style: "title",
    },
    {
      text: `Eu, ${responsavel.nomeResp}, portador(a) do CPF ${
        responsavel.cpfResp
      }, representando ${responsavel.nome} do CNPJ ${
        responsavel.cnpj
      }, localizado ${getEnderecoResponsavel(
        responsavel
      )}, na condição de responsável e responsável pelo animal de nome ${
        animal.nome
      }, classe ${animal.classe}, espécie ${animal.especie}, sexo ${
        animal.sexo
      }, declaro que NÃO autorizo a internação, tratamento clínico e/ ou cirúrgico do Animal, cuja realização seria feita pelo ${
        unidade.razao_social
      }.`,
      // text: `Eu, ${responsavel.nomeResp}, portador(a) do CPF ${
      //   responsavel.cpfResp
      // }, representando ${responsavel.nome} do CNPJ ${
      //   responsavel.cnpj
      // }, localizado ${getEnderecoResponsavel(responsavel)}, do telefone n° ${
      //   responsavel.telefone
      // }, na condição de responsável e responsável pelo animal de nome ${
      //   animal.nome
      // }, classe ${animal.classe}, espécie ${animal.especie}, sexo ${
      //   animal.sexo
      // }, declaro que NÃO autorizo a internação, tratamento clínico e/ ou cirúrgico do Animal, cuja realização seria feita pelo ${
      //   unidade.razao_social
      // }.`,
      style: "text",
    },
    {
      text: `Declaro ter sido cientificado(a) pelo(a) Médico(a) Veterinário(a) responsável sobre o quadro clínico do meu Animal e que há necessidade de realização do(s) seguinte(s) exame(s), procediment o(s) e/ ou internação:`,
      style: "text-obs",
    },
    {
      style: "table-text",
      table: {
        widths: "*",
        heights: 10,
        body: [
          [
            {
              text: "",
              border: [false, false, false, true],
            },
          ],
          [
            {
              text: "",
              border: [false, false, false, true],
            },
          ],
          [
            {
              text: "",
              border: [false, false, false, true],
            },
          ],
        ],
      },
    },
    {
      text: `Estou ciente de que o descumprimento da solicitação de exame(s) e/ ou procedimento(s) clínico(s), ambulatorial(is), anestésico(s), terapêutico(s) e/ ou cirúrgico(s) pode comprometer e/ ou prejudicar a saúde e o quadro clínico do Animal, sua qualidade de vida e sua própria vida, sendo de minha total responsabilidade as consequências dessa decisão, não havendo o que possa reclamar em qualquer esfera administrativa e/ ou jurídica contra o Médico(a) Veterinário(a) e/ ou o ${unidade.razao_social}.`,
      style: "text",
    },
    {
      text: `Assumo, ainda, ter sido esclarecido pelo(a) Médico(a) Veterinário(a) acerca dos possíveis riscos inerentes à situação clínica
      do Animal, bem como dos tratament os propostos, estando o(a) referido(a) Médico(a) Veterinário(a) e o ${unidade.razao_social}. isentos(as) de quaisquer responsabilidades decorrentes de tais riscos.
      Autorizo o tratamento e processamento dos dados pessoais do responsável acima identificado, para as finalidades estabelecidas neste termo, e na forma prevista na legislação.`,
      style: "text-obs",
    },
    {
      style: "table-obs",
      table: {
        widths: ["auto", "*"],
        heights: 10,
        body: [
          [
            {
              text: "Observações:",
              border: [false, false, false, false],
            },
            {
              text: "",
              border: [false, false, false, true],
            },
          ],
          [
            {
              text: "",
              border: [false, false, false, true],
            },
            {
              text: "",
              border: [false, false, false, true],
            },
          ],
        ],
      },
    },
    {
      text: `As informações contidas neste documento foram explicadas detalhadamente e em linguagem acessível e de fácil compreensão. Sendo assim, assino abaixo.`,
      style: "text",
    },
    {
      text: `${unidade.razao_social}, ${dateNow}`,
      style: "text-date",
    },
    {
      style: "table",
      table: {
        widths: "*",
        body: [
          [
            {
              text: "",
              border: [false, false, false, false],
            },
            {
              text: "",
              border: [false, false, false, true],
            },
            {
              text: "",
              border: [false, false, false, true],
            },
            {
              text: "",
              border: [false, false, false, true],
            },
            {
              text: "",
              border: [false, false, false, false],
            },
          ],
          [
            {
              text: "",
              border: [false, false, false, false],
            },
            {
              text: `Assinatura do(a) responsável`,
              colSpan: 3,
              alignment: "center",
              border: [false, false, false, false],
            },
            {
              text: "",
            },
            {
              text: "",
            },
            {
              text: "",
              border: [false, false, false, false],
            },
          ],
        ],
      },
    },
    {
      text: "Testemunhas",
      style: "title-testemunha",
    },
    {
      columns: [
        {
          table: {
            widths: "*",
            body: [
              [
                {
                  text: "Nome:",
                  border: [false, false, false, false],
                },
                {
                  text: "",
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: "CPF:",
                  border: [false, false, false, false],
                },
                {
                  text: "",
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: "Assinatura :",
                  border: [false, false, false, false],
                },
                {
                  text: "",
                  border: [false, false, false, false],
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: "*",
            body: [
              [
                {
                  text: "Nome:",
                  border: [false, false, false, false],
                },
                {
                  text: "",
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: "CPF:",
                  border: [false, false, false, false],
                },
                {
                  text: "",
                  border: [false, false, false, false],
                },
              ],
              [
                {
                  text: "Assinatura :",
                  border: [false, false, false, false],
                },
                {
                  text: "",
                  border: [false, false, false, false],
                },
              ],
            ],
          },
        },
      ],
    },
  ]);

  let modelo = {
    content: content,
    styles: {
      "text-header": {
        fontSize: 8,
      },
      title: {
        fontSize: 13,
        color: "#8a8a8a",
        bold: true,
        alignment: "center",
        margin: [0, 20, 0, 10],
      },
      text: {
        margin: [0, 0, 0, 10],
      },
      "text-obs": {
        margin: [0, 0, 0, 0],
      },
      "text-date": {
        alignment: "right",
        margin: [0, 0, 0, 10],
      },
      "title-testemunha": {
        fontSize: 10,
        bold: true,
        alignment: "center",
        margin: [0, 20, 0, 30],
      },
      table: {
        margin: [0, 40, 0, 0],
      },
      "table-text": {
        margin: [0, 0, 0, 10],
      },
      "table-obs": {
        margin: [0, 10, 0, 5],
      },
    },
    defaultStyle: {
      columnGap: 20,
      fontSize: 9,
      font: 'Roboto', 
    },
    footer: function (currentPage, pageCount) {
      return {
        columns: [
          { width: 20, text: "" },
          {
            width: "*",
            text: {
              // text: `Anamnese gerada por ${usuario}`,
              text: ``,
              fontSize: 6,
            },
          },
          {
            width: "*",
            text: {
              text: "Página " + currentPage.toString() + " de " + pageCount,
              alignment: "right",
              fontSize: 6,
            },
          },
          { width: 20, text: "" },
        ],
        margin: [0, 10, 0, 0],
      };
    },
  };
  return modelo;
}

function getEndereco(item) {
  return `${item.logradouro},${item.numero} | ${item.cidade} - ${item.cep}`;
}

function getEnderecoResponsavel(item) {
  return `${item.logradouro},${item.numero} | ${item.cidade} - ${item.cep}`;
}
