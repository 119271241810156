<template>
  <v-card>
    <v-toolbar style="background-color: #04b795; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Detalhe Entrada</span>
      </v-toolbar-title>
      <v-spacer> </v-spacer>
      <div v-if="perfilId == 1 && infoAnimal.status_progressao != 6">
        <v-btn
          small
          :color="editEntrada ? '#770101' : '#00868d'"
          dark
          @click="editEntrada = !editEntrada"
        >
          {{ editEntrada ? "Cancelar" : "Editar" }}
          <v-icon right v-if="!editEntrada"> mdi-pencil </v-icon>
          <v-icon right v-else> mdi-close-thick </v-icon>
        </v-btn>
        <v-btn
          v-if="editEntrada"
          class="ml-3"
          small
          color="#114c86"
          :disabled="!resumoCheck() || loading"
          :dark="resumoCheck() && !loading"
          @click="salvarEntrada()"
        >
          Atualizar
          <v-icon v-if="!loading" right>mdi-content-save </v-icon>
          <v-progress-circular
            indeterminate
            size="20"
            width="3"
            v-else
          ></v-progress-circular>
        </v-btn>
      </div>
    </v-toolbar>
    <!-- <v-card-text> -->
    <v-stepper v-model="step" non-linear>
      <v-stepper-header>
        <v-stepper-step
          step="1"
          editable
          :edit-icon="formEntrada() ? 'mdi-check' : 'mdi-pencil'"
          :complete="formEntrada()"
        >
          Entrada
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          step="2"
          editable
          :edit-icon="formAnimal() ? 'mdi-check' : 'mdi-pencil'"
          :complete="formAnimal()"
        >
          Animal
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          step="3"
          editable
          :edit-icon="formResponsavel() ? 'mdi-check' : 'mdi-pencil'"
          :complete="formResponsavel()"
        >
          Responsável
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <!-- Entrada -->
        <v-stepper-content step="1">
          <div class="container-fluid">
            <div class="row">
              <div class="col-4">
                <!-- <v-text-field
                  v-model="select_orgao"
                  label="Orgão"
                  hide-details
                  :disabled="!editEntrada"
                /> -->
                <v-select
                  v-model="select_orgao"
                  :items="list_orgao"
                  item-text="desc"
                  item-value="id"
                  label="Orgão"
                  hide-details
                  :disabled="!editEntrada"
                />
              </div>
              <div class="col-4">
                <!-- <v-text-field
                  v-model="select_entrada"
                  label="Tipo Entrada"
                  hide-details
                  :disabled="!editEntrada"
                /> -->
                <v-select
                  v-model="select_entrada"
                  :items="list_entrada"
                  item-text="desc"
                  item-value="id"
                  label="Tipo Entrada"
                  hide-details
                  :disabled="!editEntrada"
                />
              </div>
              <div class="col-4">
                <!-- <v-text-field
                  v-model="select_estado_animal"
                  label="Estado do Animal"
                  hide-details
                  :disabled="!editEntrada"
                /> -->
                <v-select
                  v-model="select_estado_animal"
                  :items="list_estado_animal"
                  item-text="desc"
                  item-value="id"
                  label="Estado do Animal"
                  hide-details
                  :disabled="!editEntrada"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <v-textarea
                  v-model="observacao_entrada"
                  label="Observação"
                  hide-details
                  rows="3"
                  :disabled="!editEntrada"
                />
              </div>
              <div class="col-2">
                <v-text-field
                  v-model="data_entrada"
                  label="Data"
                  type="date"
                  hide-details
                  :disabled="!editEntrada"
                />
                <!-- <v-text-field
                  v-model="data_entrada"
                  label="Data"
                  type="date"
                  hide-details
                  class="mb-3"
                /> -->
                <v-text-field
                  class="mt-2"
                  v-model="hora_entrada"
                  label="Hora"
                  type="time"
                  hide-details
                  :disabled="!editEntrada"
                />
              </div>
              <div class="col-2">
                <v-text-field
                  v-model="data_captura"
                  label="Data de captura"
                  type="date"
                  hide-details
                  class="mt-2"
                  :disabled="!editEntrada"
                />
                <v-text-field
                  v-model="hora_captura"
                  label="Hora de captura"
                  hide-details
                  type="time"
                  :disabled="!editEntrada"
                />
              </div>
              <div class="col-4">
                <v-text-field
                  v-model="marcacao_entrada"
                  label="Marcação"
                  hide-details
                  :disabled="!editEntrada"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <v-text-field
                  v-model="cep_entrada"
                  label="CEP"
                  hide-details
                  v-mask="'#####-###'"
                  :disabled="!editEntrada"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <v-text-field
                  v-model="logradouro_entrada"
                  label="Logradouro"
                  hide-details
                  :disabled="!editEntrada"
                />
              </div>
              <div class="col-2">
                <v-text-field
                  v-model="numero_entrada"
                  label="Número"
                  hide-details
                  :disabled="!editEntrada"
                />
              </div>
              <div class="col-3">
                <v-text-field
                  v-model="bairro_entrada"
                  label="Bairro"
                  hide-details
                  :disabled="!editEntrada"
                />
              </div>
              <div class="col-3">
                <!-- <v-text-field
                  v-model="reg_adm_entrada"
                  label="Região Administrativa"
                  hide-details
                  :disabled="!editEntrada"
                /> -->
                <v-select
                  v-model="reg_adm_entrada"
                  :items="list_reg_adm"
                  item-text="desc"
                  item-value="id"
                  label="Região Administrativa"
                  hide-details
                  :disabled="!editEntrada"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title
                        style="color: #313030; margin-bottom: 5px"
                      >
                        Cidade: {{ data.item.desc }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        style="font-weight: bold; color: #999"
                      >
                        RA: {{ data.item.ra }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <v-text-field
                  v-model="complemento_entrada"
                  label="Complemento"
                  hide-details
                  :disabled="!editEntrada"
                />
              </div>
              <div class="col-4">
                <v-text-field
                  v-model="cidade_entrada"
                  label="Cidade"
                  hide-details
                  :disabled="!editEntrada"
                />
              </div>
              <div class="col-4">
                <v-autocomplete
                  v-model="estado_entrada"
                  :items="listEstados"
                  item-text="descricao"
                  item-value="uf"
                  label="Estado"
                  hide-details
                  :disabled="!editEntrada"
                />
                <!-- <v-text-field
                  v-model="estado_entrada"
                  hide-details
                  :disabled="!editEntrada"
                /> -->
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end py-5">
            <v-btn color="#04b795" dark @click="step = 2">Seguinte</v-btn>
          </div>
        </v-stepper-content>
        <!-- Animal -->
        <v-stepper-content step="2">
          <div class="container-fluid">
            <div class="row">
              <div class="col-4">
                <v-text-field
                  v-model="rgv_animal"
                  label="RGV"
                  hide-details
                  :disabled="!editEntrada"
                />
              </div>
              <!-- <div class="col-6">
                <v-text-field
                  v-model="nome_animal"
                  label="Nome"
                  hide-details
                  :disabled="!editEntrada"
                />
              </div> -->
              <div class="col-4">
                <!-- <v-text-field
                  v-model="select_sexo"
                  label="Sexo"
                  hide-details
                  :disabled="!editEntrada"
                /> -->
                <v-select
                  v-model="select_sexo"
                  :items="list_sexo"
                  item-text="desc"
                  item-value="id"
                  label="Sexo"
                  hide-details
                  :disabled="!editEntrada"
                />
              </div>
              <div class="col-4">
                <!-- <v-text-field
                  v-model="select_idade"
                  label="Idade"
                  hide-details
                  :disabled="!editEntrada"
                /> -->
                <v-select
                  v-model="select_idade"
                  :items="list_idade"
                  item-text="desc"
                  item-value="id"
                  label="Idade"
                  hide-details
                  :disabled="!editEntrada"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <v-select
                  v-model="select_classe"
                  :items="list_classe"
                  item-text="desc"
                  item-value="id"
                  label="Classe"
                  hide-details
                  disabled
                />
              </div>
              <div class="col-4">
                <v-select
                  v-model="select_familia"
                  :items="familias"
                  item-text="desc"
                  item-value="id"
                  label="Família"
                  hide-details
                  :disabled="!editEntrada"
                />
              </div>
              <div class="col-4">
                <v-select
                  v-model="select_especie"
                  :items="especies"
                  item-text="popular"
                  item-value="id"
                  label="Espécie"
                  hide-details
                  :disabled="!editEntrada"
                >
                </v-select>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end py-5">
            <v-btn color="#04b795" dark @click="step = 3">Seguinte</v-btn>
          </div>
        </v-stepper-content>
        <!-- Responsável -->
        <v-stepper-content step="3">
          <!-- Formulários -->
          <div v-if="tipo_responsavel == 'S'">
            <div class="container-fluid">
              <div class="row">
                <div class="col-4">
                  <div>
                    <v-text-field
                      v-model="nome_responsavel"
                      label="Nome"
                      hide-details
                      class="pt-5 pb-5"
                      :disabled="!editEntrada"
                    />

                    <v-text-field
                      v-model="matricula_responsavel"
                      label="Matricula"
                      hide-details
                      class="pt-5 pb-5"
                      :disabled="!editEntrada"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="tipo_responsavel == 'F'">
            <div class="container-fluid">
              <div class="row">
                <div class="col-4">
                  <v-text-field
                    v-model="cpf_responsavel"
                    label="CPF"
                    hide-details
                    v-mask="'###.###.###-##'"
                    :disabled="!editEntrada"
                  />
                </div>
                <div class="col-8">
                  <v-text-field
                    v-model="nome_responsavel"
                    label="Nome"
                    hide-details
                    :disabled="!editEntrada"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <v-text-field
                    v-model="rg_responsavel"
                    label="RG"
                    hide-details
                    :disabled="!editEntrada"
                  />
                </div>
                <div class="col-3">
                  <v-text-field
                    v-model="nascimento_responsavel"
                    label="Data"
                    type="date"
                    hide-details
                    :disabled="!editEntrada"
                  />
                </div>
                <div class="col-3">
                  <!-- <v-text-field
                  v-model="genero_responsavel"
                  label="Gênero"
                  hide-details
                  :disabled="!editEntrada"
                /> -->
                  <v-select
                    v-model="genero_responsavel"
                    :items="list_genero_repsonsavel"
                    item-text="desc"
                    item-value="id"
                    label="Gênero"
                    hide-details
                    :disabled="!editEntrada"
                  />
                </div>
              </div>
              <div class="row">
                <!-- <div class="col-4">
                  <v-text-field
                    v-model="matricula_responsavel"
                    label="Matricula"
                    hide-details
                    :disabled="!editEntrada"
                  />
                </div> -->
                <div class="col-4">
                  <v-text-field
                    v-model="telefone1_responsavel"
                    v-mask="'(##)#####-####'"
                    label="Telefone 1"
                    hide-details
                    :disabled="!editEntrada"
                  />
                </div>
                <div class="col-4">
                  <v-text-field
                    v-model="telefone2_responsavel"
                    v-mask="'(##)#####-####'"
                    label="Telefone 2"
                    hide-details
                    :disabled="!editEntrada"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <v-text-field
                    v-model="cep_responsavel"
                    label="CEP"
                    hide-details
                    v-mask="'#####-###'"
                    :disabled="!editEntrada"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <v-text-field
                    v-model="logradouro_responsavel"
                    label="Logradouro"
                    hide-details
                    :disabled="!editEntrada"
                  />
                </div>
                <div class="col-2">
                  <v-text-field
                    v-model="numero_responsavel"
                    label="Número"
                    hide-details
                    :disabled="!editEntrada"
                  />
                </div>
                <div class="col-3">
                  <v-text-field
                    v-model="bairro_responsavel"
                    label="Bairro"
                    hide-details
                    :disabled="!editEntrada"
                  />
                </div>
                <div class="col-3">
                  <!-- <v-text-field
                  v-model="reg_adm_responsavel"
                  label="Região Administrativa"
                  hide-details
                  :disabled="!editEntrada"
                /> -->
                  <v-select
                    v-model="reg_adm_responsavel"
                    :items="list_reg_adm"
                    item-text="desc"
                    item-value="id"
                    label="Região Administrativa"
                    hide-details
                    :disabled="!editEntrada"
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title
                          style="color: #313030; margin-bottom: 5px"
                        >
                          Cidade: {{ data.item.desc }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          style="font-weight: bold; color: #999"
                        >
                          RA: {{ data.item.ra }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <v-text-field
                    v-model="complemento_responsavel"
                    label="Complemento"
                    hide-details
                    :disabled="!editEntrada"
                  />
                </div>
                <div class="col-4">
                  <v-text-field
                    v-model="cidade_responsavel"
                    label="Cidade"
                    hide-details
                    :disabled="!editEntrada"
                  />
                </div>
                <div class="col-4">
                  <v-autocomplete
                    v-model="estado_responsavel"
                    :items="listEstados"
                    item-text="descricao"
                    item-value="uf"
                    label="Estado"
                    hide-details
                    :disabled="!editEntrada"
                  />
                  <!-- <v-text-field
                  v-model="estado_responsavel"
                  label="Estado"
                  hide-details
                  :disabled="!editEntrada"
                /> -->
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end py-5">
            <v-btn color="#04b795" dark @click="step = 1">Início</v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <!-- </v-card-text> -->
  </v-card>
</template>

<script>
// import EntradaDetalhe from "@/components/animal/entrada_detalhe.vue";
import ViaCepService from "@/services/via_cep_service.js";
import RegiaoAdministrativaService from "@/services/regiao_administrativa_service.js";
import ResponsavelService from "@/services/responsavel_service.js";
import AnimalService from "@/services/animal_service.js";
import EntradaService from "@/services/entrada_service.js";
import OrgaoService from "@/services/orgao_service";
import PetsService from "../../services/pets_service";
import EspecieService from "../../services/especie_service.js";
import Mixin from "@/mixins/vuex_mixin.js";
import ClassificacaoService from "@/services/classificacao_service.js";

export default {
  mixins: [Mixin],
  components: {
    // EntradaDetalhe,
  },
  props: {
    infoAnimal: Object,
  },
  data: () => ({
    services: {
      viaCepService: ViaCepService.build(),
      regiaoAdministrativaService: RegiaoAdministrativaService.build(),
      responsavelService: ResponsavelService.build(),
      animalService: AnimalService.build(),
      entradaService: EntradaService.build(),
      orgaoService: OrgaoService.build(),
      petsService: PetsService.build(),
      especieService: EspecieService.build(),
      classificacaoService: ClassificacaoService.build(),
    },
    step: 1,
    // form animal
    select_classe: "",
    select_familia: "",
    select_especie: "",
    select_sexo: "",
    select_idade: "",
    id_animal: "",
    nome_animal: "",
    rgv_animal: "",
    especies: [],
    familias: [],
    list_especie: [],
    list_familia: [],
    list_classe: [],
    list_sexo: [
      { id: "1", desc: "Macho" },
      { id: "2", desc: "Femea" },
      { id: "3", desc: "Indeterminado" },
    ],
    list_idade: [
      { id: "1", desc: "Filhote" },
      { id: "2", desc: "Jovem" },
      { id: "3", desc: "Adulto" },
      { id: "4", desc: "Não é possível verificar a idade" },
    ],
    // form responsavel
    tipo_responsavel: "F",
    id_responsavel: "",
    id_end_responsavel: "",
    nome_responsavel: "",
    cpf_responsavel: "",
    rg_responsavel: "",
    genero_responsavel: "N",
    nascimento_responsavel: "",
    matricula_responsavel: "",
    telefone1_responsavel: "",
    telefone2_responsavel: "",
    cep_responsavel: "",
    logradouro_responsavel: "",
    numero_responsavel: "",
    bairro_responsavel: "",
    reg_adm_responsavel: "",
    cidade_responsavel: "",
    estado_responsavel: "",
    complemento_responsavel: "",
    list_genero_repsonsavel: [
      { id: "N", desc: "Não Informado" },
      { id: "M", desc: "Masculino" },
      { id: "F", desc: "Feminino" },
    ],
    // form entrada
    select_orgao: "",
    select_entrada: "",
    select_estado_animal: "",
    data_entrada: "",
    hora_entrada: "",
    data_captura: "",
    hora_captura: "",
    observacao_entrada: "",
    marcacao_entrada: "",
    id_entrada: "",
    id_end_entrada: "",
    cep_entrada: "",
    logradouro_entrada: "",
    numero_entrada: "",
    bairro_entrada: "",
    reg_adm_entrada: "",
    cidade_entrada: "",
    estado_entrada: "",
    complemento_entrada: "",
    list_orgao: [
      { id: "1", desc: "BPMA" },
      { id: "2", desc: "IBAMA" },
      { id: "3", desc: "IBRAM" },
      { id: "4", desc: "CBMDF" },
      { id: "5", desc: "PCDF" },
      { id: "6", desc: "Entrega Voluntária" },
      { id: "7", desc: "Outro" },
    ],
    list_entrada: [
      { id: "1", desc: "Apreensão" },
      { id: "2", desc: "Resgate" },
      { id: "3", desc: "Entrega Voluntária" },
      { id: "4", desc: "Abandono" },
      { id: "5", desc: "Exames/Avaliações" },
    ],
    list_estado_animal: [
      { id: "3", desc: "Não foi possível identificar" },
      { id: "4", desc: "Saudável" },
      { id: "5", desc: "Aparentemente saudável" },
      { id: "1", desc: "Ferido" },
      { id: "2", desc: "Inconsciente" },
      { id: "6", desc: "Crítico" },
    ],
    list_reg_adm: [],
    listEstados: [
      { uf: "AC", descricao: "Acre" },
      { uf: "AL", descricao: "Alagoas" },
      { uf: "AP", descricao: "Amapá" },
      { uf: "AM", descricao: "Amazonas" },
      { uf: "BA", descricao: "Bahia" },
      { uf: "CE", descricao: "Ceará" },
      { uf: "DF", descricao: "Distrito Federal" },
      { uf: "ES", descricao: "Espírito Santo" },
      { uf: "GO", descricao: "Goiás" },
      { uf: "MA", descricao: "Maranhão" },
      { uf: "MT", descricao: "Mato Grosso" },
      { uf: "MS", descricao: "Mato Grosso do Sul" },
      { uf: "MG", descricao: "Minas Gerais" },
      { uf: "PA", descricao: "Pará" },
      { uf: "PB", descricao: "Paraíba" },
      { uf: "PR", descricao: "Paraná" },
      { uf: "PE", descricao: "Pernambuco" },
      { uf: "PI", descricao: "Piauí" },
      { uf: "RJ", descricao: "Rio de Janeiro" },
      { uf: "RN", descricao: "Rio Grande do Norte" },
      { uf: "RS", descricao: "Rio Grande do Sul" },
      { uf: "RO", descricao: "Rondônia" },
      { uf: "RR", descricao: "Roraima" },
      { uf: "SC", descricao: "Santa Catarina" },
      { uf: "SP", descricao: "São Paulo" },
      { uf: "SE", descricao: "Sergipe" },
      { uf: "TO", descricao: "Tocantins" },
    ],
    editEntrada: false,
    perfilId: "",
    loading: false,
  }),
  watch: {
    // select_classe() {
    //   this.select_familia = "";
    //   this.select_especie = "";
    //   if (this.select_classe) {
    //     this.list_familia = [];
    //     this.list_especie = [];
    //     for (let index = 0; index < this.familias.length; index++) {
    //       const element = this.familias[index];
    //       if (element.id_classe == this.select_classe) {
    //         this.list_familia.push(element);
    //       }
    //     }
    //   }
    // },
    // select_familia() {
    //   this.select_especie = "";
    //   if (this.select_familia) {
    //     this.list_especie = [];
    //     for (let index = 0; index < this.especies.length; index++) {
    //       const element = this.especies[index];
    //       if (element.id_familia == this.select_familia) {
    //         this.list_especie.push(element);
    //       }
    //     }
    //   }
    // },
    // select_orgao() {
    //   // Certifique-se de que o órgão foi selecionado
    //   if (this.select_orgao != "") {
    //     // Busca o órgão selecionado na lista de órgãos
    //     const orgaoSelecionado = this.list_orgao.find(
    //       (orgao) => orgao.id === this.select_orgao
    //     );
    //     // Verifica se encontrou o órgão e se possui dados de endereço
    //     if (orgaoSelecionado && orgaoSelecionado.endereco) {
    //       console.log("teste ", orgaoSelecionado);
    //       const {
    //         cep,
    //         logradouro,
    //         numero,
    //         complemento,
    //         bairro,
    //         cidade,
    //         estado,
    //         id_regiao,
    //       } = orgaoSelecionado.endereco;
    //       // Atualiza os campos de endereço
    //       this.cep_entrada = cep || "";
    //       this.logradouro_entrada = logradouro || "";
    //       this.numero_entrada = numero || "";
    //       this.complemento_entrada = complemento || "";
    //       this.bairro_entrada = bairro || "";
    //       this.cidade_entrada = cidade || "";
    //       const filtroEstado = this.listEstados.find(
    //         (ele) => ele.descricao == estado
    //       );
    //       this.estado_entrada = filtroEstado.uf || "";
    //       this.reg_adm_entrada = id_regiao || "";
    //       console.log("Endereço preenchido:", orgaoSelecionado.endereco);
    //     } else {
    //       console.log("Órgão selecionado não possui informações de endereço.");
    //     }
    //   } else {
    //     console.log("Nenhum órgão selecionado.");
    //   }
    // },
  },
  async mounted() {
    this.atualizarListaOrgaos();
    this.getListaClasse();
    this.getListaFamilia();
    this.getListaEspecies();
    this.getListaRegiaoAdmin();
    this.getInfoEntrada();
    this.perfilId = JSON.parse(sessionStorage.vuex).perfil.id;
  },
  computed: {},
  methods: {
    concluirEntrada() {
      this.services.entradaService
        .entradaHospital(this.getModel())
        .then(async (response) => {
          const body = await response.json();
          const message = body;
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({ message, color: "error" });
            const errors = body.errors;
            Object.keys(body.errors).forEach((error) => {
              if (errors[error]) {
                this.error[error] = errors[error];
              }
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: body.message || this.$global.messages.internalServerError,
            color: "sucess",
          });
          this.$emit("entradaConcluida");
        })
        .catch((e) => {
          console.log("erro ", { e });
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    salvarEntrada() {
      this.loading = true;
      this.services.entradaService
        .editarEntradaHospital(this.getModel())
        .then(async (response) => {
          const body = await response.json();
          const message = body;
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({ message, color: "error" });
            const errors = body.errors;
            Object.keys(body.errors).forEach((error) => {
              if (errors[error]) {
                this.error[error] = errors[error];
              }
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: body.message || this.$global.messages.internalServerError,
            color: "sucess",
          });
          this.$emit("entradaConcluida");
        })
        .catch((e) => {
          console.log("erro ", { e });
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resumoCheck() {
      return this.formEntrada() && this.formAnimal() && this.formResponsavel()
        ? true
        : false;
    },
    formEntrada() {
      if (
        this.validarSelect(this.select_orgao) &&
        this.validarSelect(this.select_entrada) &&
        this.validarSelect(this.select_estado_animal) &&
        this.validarForm(this.data_entrada) &&
        this.validarForm(this.hora_entrada) &&
        this.validarForm(this.data_captura) &&
        this.validarForm(this.hora_captura)
        // this.validarForm(this.hora_entrada) &&
        // this.validarForm(this.observacao_entrada) &&
        // this.validarForm(this.cep_entrada) &&
        // this.validarForm(this.logradouro_entrada) &&
        // this.validarForm(this.numero_entrada) &&
        // this.validarForm(this.bairro_entrada) &&
        // this.validarForm(this.cidade_entrada) &&
        // this.validarForm(this.estado_entrada) &&
        // this.validarForm(this.complemento_entrada)
      ) {
        return true;
      } else {
        return false;
      }
    },
    formAnimal() {
      if (
        this.validarSelect(this.select_classe) &&
        this.validarSelect(this.select_familia) &&
        this.validarSelect(this.select_especie) &&
        this.validarSelect(this.select_sexo) &&
        this.validarSelect(this.select_idade) &&
        //this.validarForm(this.nome_animal) &&
        // this.validarForm(this.peso_animal) &&
        this.validarForm(this.rgv_animal)
      ) {
        return true;
      } else {
        return false;
      }
    },
    formResponsavel() {
      if (
        this.tipo_responsavel == "F" &&
        this.validarForm(this.nome_responsavel) &&
        this.validarForm(this.cpf_responsavel) &&
        this.validarForm(this.telefone1_responsavel) &&
        this.validarForm(this.telefone2_responsavel) &&
        this.validarForm(this.cep_responsavel) &&
        this.validarForm(this.logradouro_responsavel) &&
        this.validarForm(this.numero_responsavel) &&
        this.validarForm(this.bairro_responsavel) &&
        this.validarForm(this.cidade_responsavel) &&
        this.validarForm(this.estado_responsavel) &&
        this.validarForm(this.complemento_responsavel)
      ) {
        return true;
      } else if (
        this.tipo_responsavel == "S" &&
        this.validarForm(this.nome_responsavel) &&
        this.validarForm(this.matricula_responsavel)
      ) {
        return true;
      } else {
        return false;
      }
    },
    validarForm(item) {
      if (item != "") {
        return true;
      } else {
        return false;
      }
    },
    validarSelect(item) {
      if (item != "") {
        return true;
      } else {
        return false;
      }
    },
    getDescList(id, tipoLista) {
      let item = { desc: "-" };
      if (id != "" && tipoLista != "") {
        if (tipoLista == "Sexo") {
          item = this.list_sexo.find((ele) => {
            return ele.id == id;
          });
        } else if (tipoLista == "Classe") {
          item = this.list_classe.find((ele) => {
            return ele.id == id;
          });
        } else if (tipoLista == "Especie") {
          item = this.especies.find((ele) => {
            return ele.id == id;
          });
        } else if (tipoLista == "Idade") {
          item = this.list_idade.find((ele) => {
            return ele.id == id;
          });
        } else if (tipoLista == "Orgão") {
          item = this.list_orgao.find((ele) => {
            return ele.id == id;
          });
        } else if (tipoLista == "Tipo Entrada") {
          item = this.list_entrada.find((ele) => {
            return ele.id == id;
          });
        } else if (tipoLista == "Estado Animal") {
          item = this.list_estado_animal.find((ele) => {
            return ele.id == id;
          });
        }
      }
      return tipoLista == "Especie" ? item.nome : item.desc;
    },
    resumoEntradaForm() {
      return [
        // Animal
        {
          desc: "RGV",
          resp: this.rgv_animal ? this.rgv_animal : "-",
          tipo: "animal",
        },
        { desc: "Nome", resp: this.nome_animal, tipo: "animal" },
        {
          desc: "Sexo",
          resp: this.getDescList(this.select_sexo, "Sexo"),
          tipo: "animal",
        },
        {
          desc: "Idade",
          resp: this.getDescList(this.select_idade, "Idade"),
          tipo: "animal",
        },
        {
          desc: "Classe",
          resp: this.getDescList(this.select_classe, "Classe"),
          tipo: "animal",
        },
        {
          desc: "Espécie",
          resp: this.getDescList(this.select_classe, "Especie"),
          tipo: "animal",
        },
        // Responsável
        { desc: "Nome", resp: this.nome_responsavel, tipo: "responsavel" },
        { desc: "CPF", resp: this.cpf_responsavel, tipo: "responsavel" },
        {
          desc: "Matricula",
          resp: this.matricula_responsavel,
          tipo: "responsavel",
        },
        {
          desc: "Telefone 1",
          resp: this.telefone1_responsavel,
          tipo: "responsavel",
        },
        {
          desc: "Telefone 2",
          resp: this.telefone2_responsavel,
          tipo: "responsavel",
        },
        { desc: "CEP", resp: this.cep_responsavel, tipo: "responsavel" },
        {
          desc: "Logradouro",
          resp: this.logradouro_responsavel,
          tipo: "responsavel",
        },
        { desc: "Número", resp: this.numero_responsavel, tipo: "responsavel" },
        {
          desc: "Complemento",
          resp: this.complemento_responsavel,
          tipo: "responsavel",
        },
        { desc: "Bairro", resp: this.bairro_responsavel, tipo: "responsavel" },
        { desc: "Cidade", resp: this.cidade_responsavel, tipo: "responsavel" },
        { desc: "Estado", resp: this.estado_responsavel, tipo: "responsavel" },
        // Entrada
        {
          desc: "Orgão",
          resp: this.getDescList(this.select_orgao, "Orgão"),
          tipo: "entrada",
        },
        {
          desc: "Tipo de entrada",
          resp: this.getDescList(this.select_entrada, "Tipo Entrada"),
          tipo: "entrada",
        },
        {
          desc: "Estado Animal",
          resp: this.getDescList(this.select_estado_animal, "Estado Animal"),
          tipo: "entrada",
        },
        { desc: "Data Entrada", resp: this.data_entrada, tipo: "entrada" },
        { desc: "Hora Entrada", resp: this.hora_entrada, tipo: "entrada" },
        { desc: "Observação", resp: this.observacao_entrada, tipo: "entrada" },
        { desc: "CEP", resp: this.cep_entrada, tipo: "entrada" },
        { desc: "Logradouro", resp: this.logradouro_entrada, tipo: "entrada" },
        { desc: "Número", resp: this.numero_entrada, tipo: "entrada" },
        {
          desc: "Complemento",
          resp: this.complemento_entrada,
          tipo: "entrada",
        },
        { desc: "Bairro", resp: this.bairro_entrada, tipo: "entrada" },
        { desc: "Cidade", resp: this.cidade_entrada, tipo: "entrada" },
        { desc: "Estado", resp: this.estado_entrada, tipo: "entrada" },
      ];
    },
    searchCepEntrada() {
      if (this.cep_entrada.length == 9) {
        const cep = this.cep_entrada.replace("-", "");
        this.services.viaCepService.buscarCep(cep).then(async (response) => {
          if (response.status == 200) {
            const resp = await response.json();
            this.logradouro_entrada = resp.logradouro;
            this.numero_entrada = "";
            this.bairro_entrada = resp.bairro;
            this.cidade_entrada = resp.localidade;
            this.estado_entrada = resp.uf;
          } else {
            this.logradouro_entrada = "";
            this.numero_entrada = "";
            this.bairro_entrada = "";
            this.cidade_entrada = "";
            this.estado_entrada = "";
          }
        });
      }
    },
    searchCepResponsavel() {
      if (this.cep_responsavel.length == 9) {
        const cep = this.cep_responsavel.replace("-", "");
        this.services.viaCepService.buscarCep(cep).then(async (response) => {
          if (response.status == 200) {
            const resp = await response.json();
            this.logradouro_responsavel = resp.logradouro;
            this.numero_responsavel = "";
            this.bairro_responsavel = resp.bairro;
            this.cidade_responsavel = resp.localidade;
            this.estado_responsavel = resp.uf;
          } else {
            this.logradouro_responsavel = "";
            this.numero_responsavel = "";
            this.bairro_responsavel = "";
            this.cidade_responsavel = "";
            this.estado_responsavel = "";
          }
        });
      }
    },
    searchResponsavelCPF() {
      if (this.cpf_responsavel.length == 14) {
        const cpf = this.cpf_responsavel
          .replaceAll("-", "")
          .replaceAll(".", "");
        this.services.responsavelService
          .buscarCpf(cpf)
          .then(async (response) => {
            if (response.status == 200) {
              const resp = await response.json();
              console.log("resp", resp);
            }
          });
      }
    },
    getModel() {
      return {
        id_entrada: this.id_entrada,
        animal: {
          id_pet: this.id_animal,
          rgv: this.rgv_animal,
          nome: this.nome_animal,
          sexo: this.select_sexo,
          idade: this.select_idade,
          id_classe: this.select_classe.toString(),
          id_especie: this.select_especie.toString(),
          // id_pet_status: "2",
        },
        responsavel: {
          id_responsavel: this.id_responsavel,
          id_endereco: this.id_end_responsavel,
          tipo: this.tipo_responsavel,
          nome: this.nome_responsavel,
          cpf: this.cpf_responsavel,
          rg: this.rg_responsavel,
          genero: this.genero_responsavel,
          data_nascimento: this.nascimento_responsavel,
          matricula: this.matricula_responsavel,
          telefone_1: this.telefone1_responsavel,
          telefone_2: this.telefone2_responsavel,
          cep: this.cep_responsavel,
          logradouro: this.logradouro_responsavel,
          numero: this.numero_responsavel,
          complemento: this.complemento_responsavel,
          referencias: "",
          bairro: this.bairro_responsavel,
          reg_adm: this.reg_adm_responsavel.toString(),
          cidade: this.cidade_responsavel,
          estado: this.estado_responsavel,
        },
        entrada: {
          id_endereco: this.id_end_entrada,
          id_ra: this.reg_adm_entrada.toString(),
          orgao: this.select_orgao.toString(),
          tipo_entrada: this.select_entrada,
          estado_animal: this.select_estado_animal,
          data_entrada: this.data_entrada,
          hora_entrada: this.hora_entrada,
          data_captura: this.data_captura,
          hora_captura: this.hora_captura,
          marcaceos: this.marcacao_entrada,
          observacao: this.observacao_entrada,
          cep: this.cep_entrada,
          logradouro: this.logradouro_entrada,
          numero: this.numero_entrada,
          complemento: this.complemento_entrada,
          referencias: "",
          bairro: this.bairro_entrada,
          reg_adm: this.reg_adm_entrada.toString(),
          cidade: this.cidade_entrada,
          uf: this.estado_entrada,
          id_unidade: JSON.parse(sessionStorage.vuex).unidade.id.toString(),
        },
      };
    },
    atualizarListaOrgaos() {
      this.list_orgao = [];
      this.services.orgaoService
        .getOrgaoPaginatedList()
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            this.list_orgao.push({
              id: element.id_orgao,
              desc: element.nome_fantasia,
              endereco: {
                cep: element.cep || "",
                numero: element.numero || "",
                logradouro: element.logradouro || "",
                complemento: element.complemento || "",
                bairro: element.bairro || "",
                cidade: element.cidade || "",
                estado: element.estado || "",
                id_regiao: element.id_regiao || "",
              },
            });
          }
        })
        .catch((error) => {
          console.error({ error });
        })
        .finally(() => {});
    },
    getListaEspecies() {
      // lenbrando que Espécies são as raças no sistema original
      this.services.classificacaoService
        .listSelectEspecie(1)
        .then(async (response) => {
          const body = await response.json();
          this.especies = body.data[0].map(
            ({ id, nome_cientifico, nome_popular, id_familia }) => {
              return {
                id: id,
                id_familia: id_familia,
                cientifico: nome_cientifico,
                popular: nome_popular,
              };
            }
          );
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});

      // this.services.petsService.getRacas().then((response) => {
      //   this.especies = response.data.racas;
      // });
    },
    getListaClasse() {
      this.services.classificacaoService
        .listSelectClasse(1)
        .then(async (response) => {
          const body = await response.json();
          // this.familias = body.data[0];
          this.list_classe = body.data[0].map(({ id, descricao }) => {
            return {
              id: id,
              desc: descricao,
            };
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
      // lenbrando que Classe são as especies no sistema original
      // this.services.especieService
      //   .getEspeciesPaginatedList({
      //     page: null,
      //     per_page: null,
      //     sort: null,
      //     desc: null,
      //     search: null,
      //   })
      //   .then(async (response) => {
      //     if (response.status !== 200) return;
      //     const { data } = await response.json();
      //     const { especies } = data;
      //     this.list_classe = especies.map(({ id, nome }) => {
      //       return {
      //         id: id,
      //         desc: nome,
      //       };
      //     });
      //   })
      //   .catch((e) => {
      //     console.error(e);
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
    },
    getListaFamilia() {
      this.services.classificacaoService
        .listSelectFamilia(1)
        .then(async (response) => {
          const body = await response.json();
          this.familias = body.data[0].map(({ id, descricao, id_classe }) => {
            return {
              id: id,
              desc: descricao,
              id_classe: id_classe,
            };
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    },
    getListaRegiaoAdmin() {
      this.services.regiaoAdministrativaService
        .getRegiaoAdmin()
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            this.list_reg_adm.push({
              id: element.id,
              ra: element.regiao,
              desc: element.cidade,
            });
          }
        });
    },
    getInfoEntrada() {
      this.services.animalService
        .getEntradaAnimalbyId(this.infoAnimal.id)
        .then(async (response) => {
          if (response.status == 200) {
            const { data } = await response.json();
            const info = data;
            console.log("info", info);
            // animal
            this.id_animal = info.pet_data.id_pet;
            this.rgv_animal = info.pet_data.rgv;
            this.nome_animal = info.pet_data.nome;
            this.select_sexo = info.pet_data.id_sexo;
            // this.select_sexo = info.pet_data.sexo;
            this.select_idade = info.pet_data.id_idade;
            // this.select_idade = info.pet_data.idade;
            this.select_classe = info.pet_data.id_classe;
            this.select_familia = info.pet_data.id_familia;
            this.select_especie = info.pet_data.id_especie;
            // this.select_classe = info.pet_data.descricao_classe;
            // this.select_familia = this.getFamiliaDesc(info.pet_data.id_especie);
            // this.select_especie = `Popular: ${info.pet_data.nome_popular} | Científico ${info.pet_data.nome_cientifico}`;
            // responsavel
            this.id_responsavel = info.responsavel_data.id_responsavel_entrega;
            this.id_end_responsavel = info.responsavel_data.id_endereco;
            this.nome_responsavel = info.responsavel_data.nome;
            this.cpf_responsavel = info.responsavel_data.cpf;
            this.rg_responsavel = info.responsavel_data.rg;
            this.genero_responsavel = info.responsavel_data.genero;
            // this.genero_responsavel = info.responsavel_data.genero;
            this.nascimento_responsavel = info.responsavel_data.data_nascimento;
            this.matricula_responsavel =
              info.responsavel_data.matricula_servidor;
            this.telefone1_responsavel = info.responsavel_data.telefone_1;
            this.telefone2_responsavel = info.responsavel_data.telefone_2;
            this.id_end_responsavel = info.responsavel_data.id_endereco;
            this.cep_responsavel = info.responsavel_data.cep;
            this.logradouro_responsavel = info.responsavel_data.logradouro;
            this.numero_responsavel = info.responsavel_data.numero;
            this.complemento_responsavel = info.responsavel_data.complemento;
            this.bairro_responsavel = info.responsavel_data.bairro;
            this.reg_adm_responsavel = info.responsavel_data.id_ra;
            // this.reg_adm_responsavel = info.responsavel_data.regiao_cidade;
            this.cidade_responsavel = info.responsavel_data.cidade;
            this.estado_responsavel = info.responsavel_data.estado;
            this.tipo_responsavel = info.responsavel_data.tipo;
            // entrada
            this.id_entrada = info.entrada_data.id;
            this.select_orgao = info.orgao_data.id_orgao;
            // this.select_orgao = info.orgao_data.razao_social;
            this.select_entrada = info.entrada_data.id_tipo_entrada;
            // this.select_entrada = info.entrada_data.tipo_entrada;
            this.select_estado_animal = info.entrada_data.id_estado_animal;
            // this.select_estado_animal = info.entrada_data.estado_animal;
            this.data_entrada = info.entrada_data.data_entrada;
            const hora = info.entrada_data.hora_entrada.split(":");
            this.hora_entrada = `${hora[0]}:${hora[1]}`;
            this.data_captura = info.entrada_data.data_captura;
            const horaCap = info.entrada_data.hora_captura.split(":");
            this.hora_captura = `${horaCap[0]}:${horaCap[1]}`;
            // this.hora_entrada = info.entrada_data.hora_entrada;
            this.observacao_entrada = info.entrada_data.entrada_obs;
            this.marcacao_entrada = info.entrada_data.marcacoes;
            // this.observacao_entrada = info.entrada_data.observacao;
            this.id_end_entrada = info.entrada_data.id_endereco;
            this.cep_entrada = info.entrada_data.cep;
            this.logradouro_entrada = info.entrada_data.logradouro;
            this.numero_entrada = info.entrada_data.numero;
            this.complemento_entrada = info.entrada_data.complemento;
            this.bairro_entrada = info.entrada_data.bairro;
            this.reg_adm_entrada = info.entrada_data.id_regiao;
            // this.reg_adm_entrada = info.entrada_data.regiao_cidade;
            this.cidade_entrada = info.entrada_data.cidade;
            this.estado_entrada = info.entrada_data.estado;
          }
        })
        .catch((err) => {
          console.err(err);
        })
        .finally(() => {});
    },
    formatData(valor) {
      const newValor = valor.splite("-");
      return `${newValor[2]}/${newValor[1]}/${newValor[0]}`;
    },
    formatHora(valor) {
      const newValor = valor.splite(":");
      return `${newValor[0]}:${newValor[1]}`;
    },
  },
};
</script>
<style scoped></style>
