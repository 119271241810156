import { FormatarDataHora, DataHoraHj, LogoHeader } from "./utils_docs";

export function DeclaracaoObito(
  animal,
  responsavel,
  unidade,
  dataSaida,
  usuario,
  internacao = { valida: false }
) {
  let content = [];

  const dateNow = DataHoraHj();
  const dateSaida = FormatarDataHora(
    dataSaida.data_saida,
    dataSaida.hora_saida
  );

  content.push({
    columns: [
      {
        stack: [
          {
            image: LogoHeader(unidade.id),
            width: 55,
          },
        ],
        alignment: "left",
        border: [false, false, false, false],
      },
      {
        type: "none",
        ol: [
          { text: "Unidade", style: "text-header", bold: true },
          { text: unidade.razao_social, style: "text-header" },
          { text: "Endereço", style: "text-header", bold: true },
          { text: getEndereco(unidade), style: "text-header" },
        ],
      },
    ],
  });
  if (internacao.valida) {
    const entradaInternacao = FormatarDataHora(
      internacao.data_entrada,
      internacao.hora_entrada
    );
    content.push([
      {
        text: "Declaração de Óbito na internação",
        style: "title",
      },
      {
        text: `Entrada da internação ${entradaInternacao}, saída ${dateSaida}.`,
        style: "text-date",
      },
    ]);
  } else {
    content.push([
      {
        text: "Declaração de Óbito",
        style: "title",
      },
      {
        text: `${unidade.razao_social}, saída do animal ${dateSaida}.`,
        style: "text-date",
        alignment: "center",
      },
    ]);
  }

  content.push([
    {
      type: "none",
      margin: [0, 0, 0, 20],
      ol: [
        {
          text: [
            { text: "Rgv: ", style: "" },
            { text: animal.rgv, style: "", bold: true },
          ],
        },
        {
          text: [
            { text: "Nome: ", style: "" },
            { text: animal.nome, style: "", bold: true },
          ],
        },
        {
          text: [
            { text: "Classificação: ", style: "" },
            {
              text: `${animal.classe} | ${animal.familia} | ${animal.especie_cientifico}`,
              style: "",
              bold: true,
            },
          ],
        },
        {
          text: [
            { text: "Sexo: ", style: "" },
            { text: animal.sexo, style: "", bold: true },
          ],
        },
        {
          text: [
            { text: "Orgão: ", style: "" },
            { text: responsavel.nome, style: "", bold: true },
          ],
        },
        // {
        //   text: [
        //     { text: "Endereço entrada: ", style: "" },
        //     {
        //       text: getEnderecoResponsavel(responsavel),
        //       style: "",
        //       bold: true,
        //     },
        //   ],
        // },
        {
          text: [
            { text: "CNPJ: ", style: "" },
            { text: responsavel.cnpj, style: "", bold: true },
          ],
        },
        {
          text: [
            { text: "Localização do resgate: ", style: "" },
            { text: getEnderecoEntrada(responsavel), style: "", bold: true },
          ],
        },
        {
          margin: [0, 10, 0, 0],
          style: "table",
          table: {
            widths: ["auto", "*"],
            // heights: 20,
            body: [
              [
                {
                  text: "Data do obito:",
                  margin: [0, 2, 0, 2],
                  border: [true, true, false, true],
                },
                {
                  text: " ",
                  border: [false, true, true, true],
                },
              ],
              [
                {
                  text: "Suspeita:",
                  margin: [0, 2, 0, 2],
                  border: [true, true, false, true],
                },
                {
                  text: " ",
                  border: [false, true, true, true],
                },
              ],
              [
                {
                  text: "  ",
                  margin: [0, 2, 0, 2],
                  border: [true, true, false, true],
                },
                {
                  text: "  ",
                  border: [false, true, true, true],
                },
              ],
              [
                {
                  text: "Destinação",
                  margin: [0, 2, 0, 2],
                  border: [true, true, false, true],
                },
                {
                  text: " ",
                  border: [false, true, true, true],
                },
              ],
              [
                {
                  text: "(  ) Necropsia/Descarte",
                  margin: [0, 2, 0, 2],
                  border: [true, true, false, true],
                },
                {
                  text: " ",
                  border: [false, true, true, true],
                },
              ],
              [
                {
                  text: "(  ) Doação para aula. Universidade:",
                  margin: [0, 2, 0, 2],
                  border: [true, true, false, true],
                },
                {
                  text: " ",
                  border: [false, true, true, true],
                },
              ],
              [
                {
                  text: "(  ) Descarte/Incineração",
                  margin: [0, 2, 0, 2],
                  border: [true, true, false, true],
                },
                {
                  text: " ",
                  border: [false, true, true, true],
                },
              ],
            ],
          },
        },
      ],
    },
  ]);
  content.push([
    {
      style: "table",
      table: {
        widths: "*",
        body: [
          [
            {
              text: "",
              border: [false, false, false, false],
            },
            {
              text: "",
              border: [false, false, false, true],
            },
            {
              text: "",
              border: [false, false, false, true],
            },
            {
              text: "",
              border: [false, false, false, true],
            },
            {
              text: "",
              border: [false, false, false, false],
            },
          ],
          [
            {
              text: "",
              border: [false, false, false, false],
            },
            {
              text: `Responsável: ${responsavel.nome}`,
              colSpan: 3,
              alignment: "center",
              border: [false, false, false, false],
            },
            {
              text: "",
            },
            {
              text: "",
            },
            {
              text: "",
              border: [false, false, false, false],
            },
          ],
        ],
      },
    },
  ]);

  let modelo = {
    content: content,
    styles: {
      "text-header": {
        fontSize: 8,
      },
      title: {
        fontSize: 13,
        color: "#8a8a8a",
        bold: true,
        alignment: "center",
        margin: [0, 20, 0, 10],
      },
      text: {
        margin: [0, 0, 0, 10],
      },
      "text-date": {
        margin: [0, 0, 0, 20],
      },
      table: {
        margin: [0, 20, 0, 0],
      },
    },
    defaultStyle: {
      columnGap: 20,
      fontSize: 9,
      font: 'Roboto', 
    },
    footer: function (currentPage, pageCount) {
      return {
        columns: [
          { width: 20, text: "" },
          {
            width: "*",
            text: {
              text: `Gerada por ${usuario} | ${dateNow}`,
              fontSize: 6,
            },
          },
          {
            width: "*",
            text: {
              text: "Página " + currentPage.toString() + " de " + pageCount,
              alignment: "right",
              fontSize: 6,
            },
          },
          { width: 20, text: "" },
        ],
        margin: [0, 10, 0, 0],
      };
    },
  };
  return modelo;
}

function getEndereco(item) {
  return `${item.logradouro},${item.numero} | ${item.cidade} - ${item.cep}`;
}

function getEnderecoEntrada(item) {
  return `${item.ent_logradouro}, ${item.ent_numero}, ${item.ent_regcid} | ${item.ent_cidade} - ${item.ent_cep}`;
}

// function getEnderecoResponsavel(item) {
// return `${item.logradouro}, ${item.numero}, ${item.regcid} | ${item.cidade} - ${item.cep}`;
// }
