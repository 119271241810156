import HTTPService from "@/services/http_service.js";

export default class RelatorioAtendimentosService extends HTTPService {
  static build() {
    return new RelatorioAtendimentosService();
  }

  getProfissionaisAssistentesMedicos(unidadeId) {
    return this.get(`profissionais/unidade/${unidadeId}`);
  }

  pdfRelatorioAtendimentosProfissional(
    dataInicial,
    dataFinal,
    unidadeId,
    profissionalId,
    procedimentoId
  ) {
    return this.get(
      `relatorio/atendimento-profissional/pdf/${dataInicial}/${dataFinal}/${unidadeId}/${profissionalId}/${procedimentoId}`
    ).then((resp) => {
      if (resp.status !== 200) return resp;
      return resp.blob();
    });
  }

  pdfRelatorioAtendimentosProfissionalQuantitativo(
    dataInicial,
    dataFinal,
    unidade,
    profissional
  ) {
    const requestBody = {
      dataInicial,
      dataFinal,
      unidade,
      profissional,
    };

    return this.postJson(
      "relatorio/atendimento-quantitativo/pdf",
      requestBody,
      {
        responseType: "blob",
      }
    ).then((resp) => {
      if (resp.status !== 200) return resp;
      return resp.blob();
    });
  }

  excelRelatorioAtendimentosProfissionalQuantitativo(
    dataInicial,
    dataFinal,
    unidade,
    profissional
  ) {
    const requestBody = {
      dataInicial,
      dataFinal,
      unidade,
      profissional,
    };

    return this.postJson(
      "relatorio/atendimento-quantitativo/excel",
      requestBody,
      {
        responseType: "blob",
      }
    ).then((resp) => {
      if (resp.status !== 200) return resp;
      return resp.blob();
    });
  }
}
