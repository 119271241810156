<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Dashboard</h3>
      </div>
      <div class="row justify-content-center">
        <div class="col-3">
          <v-select
            :items="listAno"
            v-model="ano"
            label="Ano"
            outlined
          ></v-select>
        </div>
        <div class="col-3 text-start">
          <v-btn
            :dark="validForm()"
            :disabled="!validForm()"
            x-large
            color="#1daf80"
            @click="getRelatorio()"
          >
            Pesquisar
          </v-btn>
        </div>
      </div>
      <div v-if="content != 'sem-pesquisa'">
        <div v-if="!loading">
          <v-divider></v-divider>
          <div v-if="content == 'sucesso'">
            <div class="my-2">
              <div class="row">
                <div class="col-12">
                  <h3>Pesquisa referente ao ano {{ anoPesquisado }}</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-8 d-flex p-0">
                  <div class="col-4">
                    <v-card elevation="2" class="py-3 h-100">
                      <div class="card-dash-title">
                        <div>
                          <v-icon>mdi-bird</v-icon>
                        </div>
                        <div>Aves</div>
                      </div>
                      <v-divider></v-divider>
                      <div class="card-dash mb-1">
                        <div class="w-50 text-center">
                          {{ resumoDashboard.ave.atendimentos }}
                        </div>
                        <div class="w-50 text-center">
                          {{
                            resumoDashboard.ave.atendimentos_perc.replace(
                              ".",
                              ","
                            )
                          }}%
                        </div>
                      </div>
                      <div class="card-dash mb-1">
                        <div class="card-desc flex">Óbitos até 24h</div>
                        <div class="w-val">
                          {{ resumoDashboard.ave.obitos_ate }}
                        </div>
                      </div>
                      <div class="card-dash mb-1">
                        <div class="card-desc flex">Óbitos após 24h</div>
                        <div class="w-val">
                          {{ resumoDashboard.ave.obitos_apos }}
                        </div>
                      </div>
                    </v-card>
                  </div>
                  <div class="col-4">
                    <v-card elevation="2" class="py-3 h-100">
                      <div class="card-dash-title">
                        <v-icon>mdi-horse</v-icon> Mamifero
                      </div>
                      <v-divider></v-divider>
                      <div class="card-dash mb-1">
                        <div class="w-50 text-center">
                          {{ resumoDashboard.mamifero.atendimentos }}
                        </div>
                        <div class="w-50 text-center">
                          {{
                            resumoDashboard.mamifero.atendimentos_perc.replace(
                              ".",
                              ","
                            )
                          }}%
                        </div>
                      </div>
                      <div class="card-dash mb-1">
                        <div class="card-desc flex">Óbitos até 24h</div>
                        <div class="w-val">
                          {{ resumoDashboard.mamifero.obitos_ate }}
                        </div>
                      </div>
                      <div class="card-dash mb-1">
                        <div class="card-desc flex">Óbitos após 24h</div>
                        <div class="w-val">
                          {{ resumoDashboard.mamifero.obitos_apos }}
                        </div>
                      </div>
                    </v-card>
                  </div>
                  <div class="col-4">
                    <v-card elevation="2" class="py-3 h-100">
                      <div class="card-dash-title">
                        <v-icon>mdi-snake</v-icon> Reptil
                      </div>
                      <v-divider></v-divider>
                      <div class="card-dash mb-1">
                        <div class="w-50 text-center">
                          {{ resumoDashboard.reptil.atendimentos }}
                        </div>
                        <div class="w-50 text-center">
                          {{
                            resumoDashboard.reptil.atendimentos_perc.replace(
                              ".",
                              ","
                            )
                          }}%
                        </div>
                      </div>
                      <div class="card-dash mb-1">
                        <div class="card-desc flex">Óbitos até 24h</div>
                        <div class="w-val">
                          {{ resumoDashboard.reptil.obitos_ate }}
                        </div>
                      </div>
                      <div class="card-dash mb-1">
                        <div class="card-desc flex">Óbitos após 24h</div>
                        <div class="w-val">
                          {{ resumoDashboard.reptil.obitos_apos }}
                        </div>
                      </div>
                    </v-card>
                  </div>
                </div>
                <div class="col-4">
                  <v-card elevation="2" class="py-0 h-100">
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr class="table-disabled">
                            <td class="pl-0">
                              <div class="d-flex p-0">
                                <div class="table-dash-desc flex">
                                  <div>Total de Atendimentos</div>
                                  <div>Total de Óbitos (Após 24H)</div>
                                </div>
                                <div class="table-dash-num">
                                  <div>
                                    {{ resumoDashboard.total.atendimento }}
                                  </div>
                                  <div>
                                    {{ resumoDashboard.total.obito_apos }}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div>
                                {{
                                  resumoDashboard.total.obito_apos_perc.replace(
                                    ".",
                                    ","
                                  )
                                }}%
                              </div>
                            </td>
                          </tr>
                          <tr class="table-disabled">
                            <td class="pl-0">
                              <div class="d-flex p-0">
                                <div class="table-dash-desc-total flex">
                                  <div>Total de Óbitos (Até 24H)</div>
                                </div>
                                <div class="table-dash-num-total">
                                  <div>
                                    {{ resumoDashboard.total.obito_ate }}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div>
                                {{
                                  resumoDashboard.total.obito_ate_perc.replace(
                                    ".",
                                    ","
                                  )
                                }}%
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-4">
                  <h5>(%) Status por grupo de animais</h5>
                  <ChartStatusGrp
                    :lista="statusGpA"
                    :color="['#c7aa06', '#06c720', '#6d6e6d']"
                  />
                </div>
                <div class="col-8">
                  <h5>(F.A) Procedimentos Executados</h5>
                  <ChartProcExe
                    :lista="procExecutados"
                    :color="getColorProcExe()"
                  />
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-12">
                    <h5>(F.A) Procedência - Região Administrativa</h5>
                    <ChartProcRA />
                </div>
              </div> -->
              <div class="row">
                <div class="col-3">
                  <h5>(D) Tempo Médio de Permanência</h5>
                  <v-card elevation="2" class="py-1 mb-3">
                    <h5 class="text-center"><strong>Com óbitos</strong></h5>
                    <v-divider></v-divider>
                    <div class="card-dash-tmp mb-1">
                      <div class="w-50 text-center">
                        <div class="card-dash-title">
                          <div>
                            <v-icon>mdi-bird</v-icon>
                          </div>
                          <div>Aves</div>
                        </div>
                      </div>
                      <div class="w-50 text-center">
                        {{ resumoDashboard.ave.media_dia_c_obito }}
                      </div>
                    </div>
                    <div class="card-dash-tmp mb-1">
                      <div class="w-50 text-right">
                        <div class="card-dash-title">
                          <div>
                            <v-icon>mdi-horse</v-icon>
                          </div>
                          <div>Mamifero</div>
                        </div>
                      </div>
                      <div class="w-50 text-center">
                        {{ resumoDashboard.mamifero.media_dia_c_obito }}
                      </div>
                    </div>
                    <div class="card-dash-tmp mb-1">
                      <div class="w-50 text-center">
                        <div class="card-dash-title">
                          <div>
                            <v-icon>mdi-snake</v-icon>
                          </div>
                          <div>Reptil</div>
                        </div>
                      </div>
                      <div class="w-50 text-center">
                        {{ resumoDashboard.reptil.media_dia_c_obito }}
                      </div>
                    </div>
                  </v-card>
                  <v-card elevation="2" class="py-1">
                    <h5 class="text-center">
                      <strong>Sem óbitos</strong>
                    </h5>
                    <v-divider></v-divider>
                    <div class="card-dash-tmp mb-1">
                      <div class="w-50 text-center">
                        <div class="card-dash-title">
                          <div>
                            <v-icon>mdi-bird</v-icon>
                          </div>
                          <div>Aves</div>
                        </div>
                      </div>
                      <div class="w-50 text-center">
                        {{ resumoDashboard.ave.media_dia_s_obito }}
                      </div>
                    </div>
                    <div class="card-dash-tmp mb-1">
                      <div class="w-50 text-right">
                        <div class="card-dash-title">
                          <div>
                            <v-icon>mdi-horse</v-icon>
                          </div>
                          <div>Mamifero</div>
                        </div>
                      </div>
                      <div class="w-50 text-center">
                        {{ resumoDashboard.mamifero.media_dia_s_obito }}
                      </div>
                    </div>
                    <div class="card-dash-tmp mb-1">
                      <div class="w-50 text-center">
                        <div class="card-dash-title">
                          <div>
                            <v-icon>mdi-snake</v-icon>
                          </div>
                          <div>Reptil</div>
                        </div>
                      </div>
                      <div class="w-50 text-center">
                        {{ resumoDashboard.reptil.media_dia_s_obito }}
                      </div>
                    </div>
                  </v-card>
                </div>
                <div class="col-9">
                  <h5>Quantidade de Animais Atendidos por Mês</h5>
                  <ChartMesAt :lista="atendimentoMes" />
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h5 class="text-center">Espécies Mais Atendidas</h5>
                </div>
                <div class="col-4">
                  <v-card elevation="2" class="py-1" style="height: 100%">
                    <div class="card-dash-title">
                      <div>
                        <v-icon>mdi-bird</v-icon>
                      </div>
                      <div>Aves</div>
                    </div>
                    <v-divider class="mb-0"></v-divider>
                    <v-progress-circular
                      class="mt-5"
                      indeterminate
                      :color="avesColor"
                      v-if="avesEspecies.loading"
                    ></v-progress-circular>
                    <div
                      v-else
                      class="px-3 d-flex flex-column justify-content-center"
                      style="height: 200px"
                    >
                      <div v-if="avesEspecies.descricao.length == 0">
                        Sem Dados
                      </div>
                      <ChartEspAt
                        :lista="avesEspecies"
                        :color="avesColor"
                        v-else
                      />
                    </div>
                  </v-card>
                </div>
                <div class="col-4">
                  <v-card elevation="2" class="py-1" style="height: 100%">
                    <div class="card-dash-title">
                      <div>
                        <v-icon>mdi-horse</v-icon>
                      </div>
                      <div>Mamiferos</div>
                    </div>
                    <v-divider class="mb-0"></v-divider>
                    <v-progress-circular
                      class="mt-5"
                      indeterminate
                      :color="mamiferosColor"
                      v-if="mamiferosEspecies.loading"
                    ></v-progress-circular>
                    <div
                      v-else
                      class="px-3 d-flex flex-column justify-content-center"
                      style="height: 200px"
                    >
                      <div v-if="mamiferosEspecies.descricao.length == 0">
                        Sem Dados
                      </div>
                      <ChartEspAt
                        :lista="mamiferosEspecies"
                        :color="mamiferosColor"
                        v-else
                      />
                    </div>
                  </v-card>
                </div>
                <div class="col-4">
                  <v-card elevation="2" class="py-1" style="height: 100%">
                    <div class="card-dash-title">
                      <div>
                        <v-icon>mdi-snake</v-icon>
                      </div>
                      <div>Reptils</div>
                    </div>
                    <v-divider class="mb-0"></v-divider>
                    <v-progress-circular
                      class="mt-5"
                      indeterminate
                      :color="reptilesColor"
                      v-if="reptilesEspecies.loading"
                    ></v-progress-circular>
                    <div
                      v-else
                      class="px-3 d-flex flex-column justify-content-center"
                      style="height: 200px"
                    >
                      <div v-if="reptilesEspecies.descricao.length == 0">
                        Sem Dados
                      </div>
                      <ChartEspAt
                        :lista="reptilesEspecies"
                        :color="reptilesColor"
                        v-else
                      />
                    </div>
                  </v-card>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h5>(F.A) Procedência - Região Administrativa</h5>
                  <ChartProcRANew :lista="procRA" />
                </div>
              </div>
            </div>
          </div>
          <div v-else class="row">
            <div class="col-12">
              <h2 class="text-center">Sem Pesquisa</h2>
            </div>
          </div>
        </div>
        <v-progress-circular
          class="mt-10"
          :size="50"
          indeterminate
          :color="'#1daf80'"
          v-else
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import VuexMixin from "@/mixins/vuex_mixin";
import ChartStatusGrp from "@/components/dashboard_charts/chart_status_grp.vue";
import DashboardRelatorioService from "@/services/dashboard_relatorio_service.js";
import ChartProcExe from "@/components/dashboard_charts/chart_proc_exc.vue";
// import ChartProcRA from "@/components/dashboard_charts/chart_proc_ra.vue";
import ChartProcRANew from "@/components/dashboard_charts/chart_proc_ra_new.vue";
// import ChartTempPerm from "@/components/dashboard_charts/chart_temp_perm.vue";
import ChartMesAt from "@/components/dashboard_charts/chart_mes_aten.vue";
import ChartEspAt from "@/components/dashboard_charts/chart_esp_aten.vue";

export default {
  mixins: [VuexMixin],
  components: {
    ChartStatusGrp,
    ChartProcExe,
    // ChartProcRA,
    // ChartTempPerm,
    ChartMesAt,
    ChartEspAt,
    ChartProcRANew,
  },
  data: () => ({
    listAno: [],
    ano: "",
    anoPesquisado: "",
    idUnidade: JSON.parse(sessionStorage.getItem("vuex")).unidade.id,
    services: {
      dashboardRelatorioService: DashboardRelatorioService.build(),
    },
    loading: true,
    content: "sem-pesquisa", // "sem-pesquisa", "sem-dados", "sucesso"
    avesColor: "#0c35ed",
    mamiferosColor: "#de660b",
    reptilesColor: "#08a63f",
    statusGpA: [],
    procExecutados: {},
    resumoDashboard: {},
    atendimentoMes: [],
    procRA: { descricao: [], atendimentos: [] },
    avesEspecies: { loading: false, descricao: [], atendimentos: [] },
    mamiferosEspecies: { loading: false, descricao: [], atendimentos: [] },
    reptilesEspecies: { loading: false, descricao: [], atendimentos: [] },
  }),
  mounted() {
    this.getListAno();
    // this.getAtendimentoEspecie();
  },
  methods: {
    limparListas() {
      this.statusGpA = [];
      this.procExecutados = {};
      this.resumoDashboard = {};
      this.atendimentoMes = [];
      this.procRA = { descricao: [], atendimentos: [] };
      this.avesEspecies = { loading: false, descricao: [], atendimentos: [] };
      this.mamiferosEspecies = {
        loading: false,
        descricao: [],
        atendimentos: [],
      };
      this.reptilesEspecies = {
        loading: false,
        descricao: [],
        atendimentos: [],
      };
    },
    getRelatorio() {
      this.limparListas();
      if (this.ano) {
        this.loading = true;
        this.content = "sem-dados";
        this.anoPesquisado = "";
        this.services.dashboardRelatorioService
          .getRelatorioUnidadeAno(this.idUnidade, this.ano)
          .then(async (response) => {
            if (response.status == 200) {
              const { data } = await response.json();
              this.tratarDados(data);
              this.content = "sucesso";
              this.anoPesquisado = this.ano;
            }
          })
          .catch((error) => {
            console.error({ error });
          })
          .finally(() => {
            // this.content = "sucesso";
            this.loading = false;
          });
      }
    },
    tratarDados(dados) {
      this.resumoDashboard = {
        total: {
          atendimento: dados.Resumo_atendimentos.total_atendimentos,
          obito_apos: dados.Resumo_atendimentos.total_obitos_apos_24,
          obito_apos_perc: dados.Resumo_atendimentos.total_obitos_apos_24_perc,
          obito_ate: dados.Resumo_atendimentos.total_obitos_ate_24,
          obito_ate_perc: dados.Resumo_atendimentos.total_obitos_ate_24_perc,
        },
        ave: {
          atendimentos: 0,
          atendimentos_perc: "0",
          obitos_apos: 0,
          obitos_apos_perc: "0",
          obitos_ate: 0,
          obitos_ate_perc: "0",
        },
        mamifero: {
          atendimentos: 0,
          atendimentos_perc: "0",
          obitos_apos: 0,
          obitos_apos_perc: "0",
          obitos_ate: 0,
          obitos_ate_perc: "0",
        },
        reptil: {
          atendimentos: 0,
          atendimentos_perc: "0",
          obitos_apos: 0,
          obitos_apos_perc: "0",
          obitos_ate: 0,
          obitos_ate_perc: "0",
        },
      };
      for (
        let index = 0;
        index < dados.atendimentos_por_classe.length;
        index++
      ) {
        const element = dados.atendimentos_por_classe[index];
        if (element.descricao == "Aves") {
          this.resumoDashboard.ave = {
            atendimentos: element.total ?? 0,
            atendimentos_perc: element.perc ?? "0",
            obitos_apos: element.obitos_24 ?? 0,
            obitos_apos_perc: element.obitos_24_perc ?? "0",
            obitos_ate: element.obitos_0 ?? 0,
            obitos_ate_perc: element.obitos_0_perc ?? "0",
          };
          this.statusGpA.push({
            desc: "Aves",
            dados: [
              {
                label: "Internado",
                value: element.internados,
                percent: element.internados_perc,
              },
              {
                label: "Alta",
                value: element.altas,
                percent: element.altas_perc,
              },
              {
                label: "Óbito",
                value: element.obitos_total,
                percent: element.obitos_total_perc,
              },
            ],
          });
        } else if (element.descricao == "Mammalia") {
          this.resumoDashboard.mamifero = {
            atendimentos: element.total ?? 0,
            atendimentos_perc: element.perc ?? "0",
            obitos_apos: element.obitos_24 ?? 0,
            obitos_apos_perc: element.obitos_24_perc ?? "0",
            obitos_ate: element.obitos_0 ?? 0,
            obitos_ate_perc: element.obitos_0_perc ?? "0",
          };
          this.statusGpA.push({
            desc: "Mamiferos",
            dados: [
              {
                label: "Internado",
                value: element.internados,
                percent: element.internados_perc,
              },
              {
                label: "Alta",
                value: element.altas,
                percent: element.altas_perc,
              },
              {
                label: "Óbito",
                value: element.obitos_total,
                percent: element.obitos_total_perc,
              },
            ],
          });
        } else if (element.descricao == "Reptilia") {
          this.resumoDashboard.reptil = {
            atendimentos: element.total ?? 0,
            atendimentos_perc: element.perc ?? "0",
            obitos_apos: element.obitos_24 ?? 0,
            obitos_apos_perc: element.obitos_24_perc ?? "0",
            obitos_ate: element.obitos_0 ?? 0,
            obitos_ate_perc: element.obitos_0_perc ?? "0",
          };
          this.statusGpA.push({
            desc: "Reptil",
            dados: [
              {
                label: "Internado",
                value: element.internados,
                percent: element.internados_perc,
              },
              {
                label: "Alta",
                value: element.altas,
                percent: element.altas_perc,
              },
              {
                label: "Óbito",
                value: element.obitos_total,
                percent: element.obitos_total_perc,
              },
            ],
          });
        }
      }
      this.checkStatusGpA();
      this.procExecutados = this.getProcedimentosExecutados(
        dados.procedimentos_executados
      );
      this.resumoDashboard.ave.media_dia_c_obito =
        dados.media_dias_classe_com_obito[1];
      this.resumoDashboard.ave.media_dia_s_obito =
        dados.media_dias_classe_sem_obito[1];
      this.resumoDashboard.mamifero.media_dia_c_obito =
        dados.media_dias_classe_com_obito[2];
      this.resumoDashboard.mamifero.media_dia_s_obito =
        dados.media_dias_classe_sem_obito[2];
      this.resumoDashboard.reptil.media_dia_c_obito =
        dados.media_dias_classe_com_obito[3];
      this.resumoDashboard.reptil.media_dia_s_obito =
        dados.media_dias_classe_sem_obito[3];

      let objKeys = Object.keys(dados.atendimentos_mes);
      for (let index = 0; index < objKeys.length; index++) {
        const element = objKeys[index];
        this.atendimentoMes.push(dados.atendimentos_mes[element]);
      }

      for (let index = 0; index < dados.top_atd_especies.length; index++) {
        const element = dados.top_atd_especies[index];
        if (element.id_classe == 1) {
          this.avesEspecies.descricao.push(element.nome_cientifico);
          this.avesEspecies.atendimentos.push(element.total);
        } else if (element.id_classe == 2) {
          this.mamiferosEspecies.descricao.push(element.nome_cientifico);
          this.mamiferosEspecies.atendimentos.push(element.total);
        } else if (element.id_classe == 3) {
          this.reptilesEspecies.descricao.push(element.nome_cientifico);
          this.reptilesEspecies.atendimentos.push(element.total);
        }
      }
      let listaRA = dados.procedencia_por_ra;
      listaRA.sort((a, b) => {
        if (a.total < b.total) {
          return 1;
        }
        if (a.total > b.total) {
          return -1;
        }
        return 0;
      });
      for (let index = 0; index < listaRA.length; index++) {
        const element = listaRA[index];
        this.procRA.descricao.push(element.cidade);
        this.procRA.atendimentos.push(element.total);
      }
    },
    checkStatusGpA() {
      if (
        !this.statusGpA.find((ele) => {
          return ele.desc == "Aves";
        })
      ) {
        this.statusGpA.push({
          desc: "Aves",
          dados: [
            {
              label: "Internado",
              value: 0,
              percent: "0",
            },
            {
              label: "Alta",
              value: 0,
              percent: "0",
            },
            {
              label: "Óbito",
              value: 0,
              percent: "0",
            },
          ],
        });
      }
      if (
        !this.statusGpA.find((ele) => {
          return ele.desc == "Mamiferos";
        })
      ) {
        this.statusGpA.push({
          desc: "Mamiferos",
          dados: [
            {
              label: "Internado",
              value: 0,
              percent: "0",
            },
            {
              label: "Alta",
              value: 0,
              percent: "0",
            },
            {
              label: "Óbito",
              value: 0,
              percent: "0",
            },
          ],
        });
      }
      if (
        !this.statusGpA.find((ele) => {
          return ele.desc == "Reptil";
        })
      ) {
        this.statusGpA.push({
          desc: "Reptil",
          dados: [
            {
              label: "Internado",
              value: 0,
              percent: "0",
            },
            {
              label: "Alta",
              value: 0,
              percent: "0",
            },
            {
              label: "Óbito",
              value: 0,
              percent: "0",
            },
          ],
        });
      }
    },
    getColorProcExe() {
      let colors = [
        "#e13a55",
        "#bc7c42",
        "#a9be00",
        "#abc753",
        "#933eab",
        "#bb92ae",
      ];
      let listColors = [];
      for (let index = 0; index < this.procExecutados.series.length; index++) {
        listColors.push(colors[index]);
      }
      return listColors;
    },
    getProcedimentosExecutados(lista) {
      let listaProcedimentos = [];
      for (let index = 0; index < lista.aves.length; index++) {
        const element = lista.aves[index];
        listaProcedimentos.push({
          id_tipo_procedimento: element.id_tipo_procedimento,
          tipo_procedimento: element.tipo_procedimento,
        });
      }
      for (let index = 0; index < lista.mamiferos.length; index++) {
        const element = lista.mamiferos[index];
        let indexFind = listaProcedimentos.findIndex((ele) => {
          ele.id_tipo_procedimento == element.id_tipo_procedimento;
        });
        if (indexFind != -1) {
          listaProcedimentos.push({
            id_tipo_procedimento: element.id_tipo_procedimento,
            tipo_procedimento: element.tipo_procedimento,
          });
        }
      }
      for (let index = 0; index < lista.reptils.length; index++) {
        const element = lista.reptils[index];
        let indexFind = listaProcedimentos.findIndex((ele) => {
          ele.id_tipo_procedimento == element.id_tipo_procedimento;
        });
        if (indexFind != -1) {
          listaProcedimentos.push({
            id_tipo_procedimento: element.id_tipo_procedimento,
            tipo_procedimento: element.tipo_procedimento,
          });
        }
      }
      let obj = { categorias: ["Ave", "Mamifero", "Reptil"], series: [] };
      for (let index = 0; index < listaProcedimentos.length; index++) {
        const element = listaProcedimentos[index];
        let value = [];
        const indexAve = lista.aves.findIndex((ele) => {
          return ele.tipo_procedimento == element.tipo_procedimento;
        });
        value.push(indexAve == -1 ? "0" : lista.aves[indexAve].total);
        const indexMamifero = lista.mamiferos.findIndex((ele) => {
          return ele.tipo_procedimento == element.tipo_procedimento;
        });
        value.push(
          indexMamifero == -1 ? "0" : lista.mamiferos[indexMamifero].total
        );
        const indexReptil = lista.reptils.findIndex((ele) => {
          return ele.tipo_procedimento == element.tipo_procedimento;
        });
        value.push(indexReptil == -1 ? "0" : lista.reptils[indexReptil].total);
        obj.series.push({ name: element.tipo_procedimento, data: value });
      }
      return obj;
    },
    getAtendimentoEspecie() {
      this.avesEspecies = {
        loading: false,
        descricao: [
          "Águia-real",
          "Andorinha",
          "Arara-azul",
          "Búfalo",
          "Cegonha",
          "Corvo",
          "Cacatua",
          "Papagaio",
          "Sabiá",
          "Jaburu",
        ],
        atendimentos: [50, 48, 46, 42, 35, 34, 30, 30, 18, 11],
      };
      this.mamiferosEspecies = {
        loading: false,
        descricao: [
          "Leão",
          "Elefante",
          "Girafa",
          "Cachorro",
          "Gato",
          "Baleia",
          "Urso",
          "Macaco",
          "Canguru",
          "Coelho",
        ],
        atendimentos: [43, 40, 38, 35, 34, 20, 16, 16, 10, 2],
      };
      this.reptilesEspecies = {
        loading: false,
        descricao: [
          "Cobra",
          "Lagarto",
          "Tartaruga",
          "Jacaré",
          "Iguana",
          "Camaleão",
          "Crocodilo",
          "Tremem",
          "Dragão barbudo",
          "Serpente",
        ],
        atendimentos: [20, 18, 16, 10, 7, 5, 4, 2, 1, 1],
      };
      // setTimeout(() => {
      //   this.loading = false;
      //   this.content = "success";
      // }, 10 * 1000);
    },
    validForm() {
      if (this.ano) {
        return true;
      } else {
        return false;
      }
    },
    getListAno() {
      const date = new Date();
      let ano = date.getFullYear();
      let listAno = [];
      for (let i = 0; i < 15; i++) {
        if (i == 0) {
          listAno.push(ano);
        } else {
          listAno.push(ano + i);
          listAno.push(ano - i);
        }
      }
      listAno.sort();
      this.ano = ano;
      this.listAno = listAno;
    },
  },
};
</script>
<style scoped>
.card-dash-tmp,
.card-dash {
  display: flex;
  justify-content: space-between;
}
.card-dash-tmp div,
.card-dash div {
  padding: 0px 5px;
}
.w-val {
  width: 50px;
}
.card-dash-title {
  font-size: 1.05rem;
  display: flex;
  justify-content: center;
  gap: 0.3rem;
  font-weight: bold;
  color: #828282;
  align-items: center;
}
.card-dash-tmp .card-dash-title {
  justify-content: start;
}
.card-desc {
  text-align: end;
  padding-left: 10px;
  padding-right: 5px;
}
.table-dash-desc div,
.table-dash-num div {
  padding: 16px 10px;
  text-align: left;
}
.table-dash-desc div:first-child,
.table-dash-num div:first-child {
  border-bottom: 1px solid #e3e3e3;
}
.table-dash-num div {
  border-right: 1px solid #e3e3e3;
}
.table-dash-desc-total div,
.table-dash-num-total div {
  padding: 16px 10px;
  text-align: left;
}
.table-dash-num-total div {
  border-right: 1px solid #e3e3e3;
}
.table-disabled:hover {
  background-color: white !important;
}
</style>
